import instance from "../axios";
import { ReducersTypes } from "../constants/reducerTypes";
import { generateQueryString } from "../utils/query";

const setData = (type, data) => ({
    type,
    payload: {
      ...data,
    },
});  

const getQuery = async (data, URL) => {
    var query = URL;
    return `${query}${generateQueryString(data)}`;
};

export const setClientData = (role = "") => async (dispatch) => {
    instance.get(role === "superadmin" ? "admin/client" : "client")
        .then(res => {
            dispatch(setData(ReducersTypes.SET_CLIENT_DETAILS, { clientDetails: role === "superadmin" ? res.data.result[0] : res.data.result}));
        })
}

export const createNewClient = (params = {}) =>  async (dispatch) => {
    return instance.post("admin/client", params)
}