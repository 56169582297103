import "../../assets/css/paymentdetails.css";
import get from "lodash/get";
import { convertAmountWithDecimal } from "../../utils/amount";
import { default as React, useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomSpinner from "../../components/Spinner";
import "../../assets/css/payroll.css";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import FileUpload from "./files";
import toast from "react-hot-toast";
import { omit } from "lodash";
import ClickAwayListener from "../../components/ClickAwayListener";

function CreateNewInvoice(props) {
  const { 
    history, 
    createInvoice, 
    updateInvoice, 
    getInvoice,
    removeLines,
    getChartData,
    setInvoiceData,
    getInvoiceList,
    getQueryObj,
    label, 
    getInvoiceAttachments,
    customerDetails: {
        showModal,
        invoiceInfo = {},
        invoiceList,
        customerData
    },
    invoiceDetails: {
        forms
  }} = props;
  const invoiceId = get(invoiceInfo,"id","")
  const [postdata, setpostdata] = useState(() => {
    return {
        invoice_date: moment(get(invoiceInfo,"invoice_date","")).format("DD/MM/YYYY"),
        due_date: moment(get(invoiceInfo,"due_date","")).format("DD/MM/YYYY"),
        header_note: get(invoiceInfo,"header_note",""),
        invoice_type: get(invoiceInfo,"invoice_type","INVOICE"),
        passenger_name: get(invoiceInfo,"passenger_name",""),
        passenger_address: get(invoiceInfo,"passenger_address",""),
        passenger_state: get(invoiceInfo,"passenger_state",""),
        passenger_pincode: get(invoiceInfo,"passenger_pincode",""),
        passenger_contact: get(invoiceInfo,"passenger_contact",""),
        
      }
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [subtotal, setSubtotal] = useState(0.0);
  const [tax, setTax] = useState(0.0);
  const [taxInput, setTaxInput] = useState({
    cgst: 0.0,
    igst: 0.0,
    sgst: 0.0,
    service_charge: 0.0
  });
  const [lineItemList, setLineItemList] = useState(get(invoiceInfo,"invoice_lines",[]) || []);
  const [originallineItems, setOriginalLineItems] = useState(get(invoiceInfo,"invoice_lines",[]) || []);
  const [isNewLineItemEnabled, setIsNewLineItemEnabled] = useState(false);
  const [newLineItemRow, setNewLineItemRow] = useState({});
  const [files, setFiles] = useState("");
  const [deletedLines, setDeletedLines] = useState([]);
  const [saving, setSaving] = useState(false);
  const [editableRow, setEditableRow] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(get(invoiceInfo,"taxes","")) {
        const tax = get(invoiceInfo,"taxes","").split(",");
        setTaxInput({
            cgst: Number(tax[0].split("=")[1]) || 0.0,
            igst: Number(tax[1].split("=")[1]) || 0.0,
            sgst: Number(tax[2].split("=")[1]) || 0.0,
            service_charge: Number(tax[3].split("=")[1]) || 0.0
        });
    }
    setLineItemList(get(invoiceInfo,"invoice_lines",[]))
    setpostdata({
        invoice_date: moment(get(invoiceInfo,"invoice_date","")).format("DD/MM/YYYY"),
        due_date: moment(get(invoiceInfo,"due_date","")).format("DD/MM/YYYY"),
        header_note: get(invoiceInfo,"header_note",""),
        invoice_type: get(invoiceInfo,"invoice_type","INVOICE"),
        passenger_name: get(invoiceInfo,"passenger_name",""),
        passenger_address: get(invoiceInfo,"passenger_address",""),
        passenger_state: get(invoiceInfo,"passenger_state",""),
        passenger_pincode: get(invoiceInfo,"passenger_pincode",""),
        passenger_contact: get(invoiceInfo,"passenger_contact",""),

    })
  },[invoiceInfo]);

  useEffect(() => {
    getSubTotal();
  }, [lineItemList, newLineItemRow]);

  useEffect(() => {
    let rowsWithTax = lineItemList;
    let newTax = 0;
   
    getSubTotal();

    if(taxInput.cgst) {
        newTax += getTax(taxInput.cgst);
    }
    if(taxInput.igst) {
        newTax += getTax(taxInput.igst);
    }
    if(taxInput.sgst) {
        newTax += getTax(taxInput.sgst);
    }

    setTax(newTax || 0);
  }, [taxInput, lineItemList, newLineItemRow, subtotal]);

  function getTax(tax = 0) {
    return subtotal * (tax / 100);
  }

  function getSubTotal() {
    let newSubtotal = lineItemList.reduce(
        (n, { amount }) => n + amount,
        0
      );
    setSubtotal(newSubtotal);
  }

  function setModifiedNewLineItemRow(field, value, productValue) {
    let modifiedNewLineItemRow = JSON.parse(JSON.stringify(newLineItemRow));
    modifiedNewLineItemRow[field] = value;
    if (productValue) modifiedNewLineItemRow["amount"] = productValue;
    setNewLineItemRow(modifiedNewLineItemRow);
  }

   function refreshPage() {
    setInvoiceData({
        invoiceInfo: "",
        showModal: false,
    },"CUSTOMER_DETAILS");
    toast.success('Updated successfully!')
    getInvoiceList(getQueryObj());
    getChartData();
    setSaving(false);
  }

  return (
    <div>
    <Modal show={showModal} onHide={() => {
        setInvoiceData({
            showModal: false,
            invoiceInfo: ""
        }, "CUSTOMER_DETAILS")
    }} size="xl">
        <Modal.Header>
            <h4>{label}</h4>
        </Modal.Header>
            <Modal.Body>
            <div className="card-body">
            {isLoading ? (
                <CustomSpinner isfullscreen />
            ) : (
                <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <div className="col-md-6">
                        <h4 className="t-18 font-weight-bold mb-1">{get(customerData,"name","-")}</h4>
                        <div>
                            {get(customerData,"physical_address","-")}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="t-14 font-weight-bold text-right">
                            Amount Due
                        </div>
                        <strong className="t-18 font-weight-bold text-right">
                            {subtotal}
                        </strong>
                    </div>
                    </div>
                    <hr className="mt-2"></hr>
                    <div className="t-18"> Invoice Details: </div>
                    { get(customerData,"override_details") ? <>
                    <div className="row">
                        <div className="col-md-3 mt-2">
                            <label>Passenger Name</label>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setpostdata({
                                        ...postdata,
                                        passenger_name: e.target.value
                                    })
                                }}
                                placeholder="Enter Name"
                                className="form-control p-2 border"
                                value={get(postdata,"passenger_name","")}
                            />  
                        </div>
                        <div className="col-md-3 mt-2">
                            <label>Address</label>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setpostdata({
                                        ...postdata,
                                        passenger_address: e.target.value
                                    })
                                }}
                                placeholder="Enter address"
                                className="form-control p-2 border"
                                value={get(postdata,"passenger_address","")}
                            />
                        </div>
                        <div className="col-md-3 mt-2">
                            <div className="row">
                            <div className="col-md-6">
                                <label>state</label>
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setpostdata({
                                            ...postdata,
                                            passenger_state: e.target.value
                                        })
                                    }}
                                    placeholder="Enter state"
                                    className="form-control p-2 border"
                                    value={get(postdata,"passenger_state","")}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>zip code</label>
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setpostdata({
                                            ...postdata,
                                            passenger_pincode: e.target.value
                                        })
                                    }}
                                    placeholder="Enter zip code"
                                    className="form-control p-2 border"
                                    value={get(postdata,"passenger_pincode","")}
                                />
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-2">
                                <label>Contact</label>
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setpostdata({
                                            ...postdata,
                                            passenger_contact: e.target.value
                                        })
                                    }}
                                    placeholder="Enter Email/Phone No."
                                    className="form-control p-2 border"
                                    value={get(postdata,"passenger_contact","")}
                                />
                        </div>
                    </div>
                    <hr></hr>
                    </> : ""
                    }
                    <div className="row">
                    <div className="col-md-3 mt-2">
                            <strong>Invoice Status</strong>
                            <select
                                className="form-control"
                                value={get(postdata,"invoice_type","")}
                                onChange={(e) => {
                                    setpostdata({
                                        ...postdata,
                                        invoice_type: e.target.value
                                    })
                                }}
                            >
                                <option value="INVOICE">
                                    Invoice
                                </option>
                                <option value="PROFORMA INVOICE">
                                    Proforma Invoice
                                </option>
                                <option value="CREDIT NOTE">
                                    Credit Note
                                </option>
                            </select>
                            {/* </div> */}
                        </div>
                        <div className="col-md-3 mt-2">
                            <label>Note*</label>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setpostdata({
                                        ...postdata,
                                        header_note: e.target.value
                                    })
                                }}
                                placeholder="Enter note"
                                className="form-control p-2 border"
                                value={get(postdata,"header_note","")}
                            />
                        </div>
                        <div className="col-md-3 mt-2">
                            <label>Due Date</label>
                            <input
                                type={"date"}
                                className="w-100 border rounded p-2"
                                value={get(postdata,"due_date","") && get(postdata,"due_date","").split("/").reverse().join("-")}
                                onChange={(e) => {
                                    e.target.value && setpostdata({
                                        ...postdata,
                                        due_date: e.target.value.split("-").reverse().join("/")
                                    })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mt-2">
                            <label>Invoice Date*</label>
                            <input
                                type={"date"}
                                className="w-100 border rounded p-2"
                                value={get(postdata,"invoice_date","") && get(postdata,"invoice_date","").split("/").reverse().join("-")}
                                onChange={(e) => {
                                    e.target.value && setpostdata({
                                        ...postdata,
                                        invoice_date: e.target.value.split("-").reverse().join("/")
                                    })
                                }}
                                //disabled = {label === "Update Invoice" ? 1 : 0}
                            />
                        </div>
                    </div>
                    <ClickAwayListener onClickAway={() => {
                        if(editableRow) {

                            let data = lineItemList[editableRow.split("-")[1]];
                            var taxes = get(data,"taxes","") ? get(data,"taxes","").split(",") : "";
                            var cgstt = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
                            var igstt = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
                            var sgstt = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;

                            if(data) {
                                const {
                                    cgst = cgstt,
                                    igst = igstt,
                                    sgst = sgstt,
                                    amount = 0,
                                    description = ""
                                } = data;
                                if(!description || !amount) {
                                    toast.error("Please fill required fields");
                                    return;
                                }
                                let newData = {
                                    ...data,
                                    tax_amount: (cgst/100 * amount) + (igst/100 * amount) + (sgst/100 * amount),
                                    taxes: `cgst=${parseFloat(cgst)},igst=${parseFloat(igst)},sgst=${parseFloat(sgst)}`,
                                };
                                
                                let newList = lineItemList;
                                newList[editableRow.split("-")[1]] = omit(newData,["cgst,igst,sgst"]);
                                setLineItemList(newList);
                                setEditableRow("");
                                getSubTotal();
                            }

                            return;
                        }
                        if(!isNewLineItemEnabled)
                            return;
                        let description = get(
                            newLineItemRow,
                            "description",
                            ""
                            ).trim();
                            let cgst = get(
                                newLineItemRow,
                                "cgst",
                                ""
                                ).trim();
                            let igst = get(
                                newLineItemRow,
                                "igst",
                                ""
                                ).trim();
                            let sgst = get(
                                newLineItemRow,
                                "sgst",
                                ""
                                ).trim();
                            let amount = get(
                            newLineItemRow,
                            "amount",
                            ""
                            ).trim();
                            if (
                            description === "" ||
                            amount === ""
                            ) {
                                toast.error("Please fill required fields")
                            } else {
                            let newData = {
                                description,
                                amount: parseFloat(amount),
                                tax_amount: (cgst/100 * amount) + (igst/100 * amount) + (sgst/100 * amount),
                                taxes: `cgst=${parseFloat(cgst)},igst=${parseFloat(igst)},sgst=${parseFloat(sgst)}`
                            };
                                setLineItemList([...lineItemList, newData]);
                                setNewLineItemRow({});
                                setIsNewLineItemEnabled(false);
                            }
                    }}>
                    <div className="row mt-5 payrolltable pb-1 px-3">
                    <table>
                        <tbody>
                        <tr>
                            <th className="w-40">Description*</th>
                            <th className="text-center">Amount*</th>
                            <th className="w-10 text-center">SGST(%)</th>
                            <th className="w-10 text-center">CGST(%)</th>
                            <th className="w-10 text-center">IGST(%)</th>
                            <th className="text-center" hidden={isNewLineItemEnabled || editableRow}>Final Amount</th>
                            <th></th>
                        </tr>
                        {lineItemList.length === 0 && !isNewLineItemEnabled && (
                            <tr>
                            <td className="text-center" colSpan={10}>
                                No rows
                            </td>
                            </tr>
                        )}
                        {lineItemList.map((item, index) => {
                            var taxes = get(item,"taxes","") ? get(item,"taxes","").split(",") : "";
                            var cgst = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
                            var igst = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
                            var sgst = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;
                            const isEditable = `line-${index}` === editableRow;
                            return (
                            <tr id={`line-${index}`} className="cursor-pointer" onClick={() => {
                                setEditableRow(`line-${index}`);
                            }}>
                                <td>
                                    {isEditable ? (
                                        <input
                                            defaultValue={item.description}
                                            contentEditable='true'
                                            id="description"
                                            className="form-control t-12"
                                            type="text"
                                            placeholder={item.description}
                                            onChange={(e) => {
                                                let newLineData = lineItemList;
                                                newLineData[index].description = e.target.value
                                                setLineItemList(newLineData);
                                            }}
                                        ></input>
                                    ) : item.description}
                                </td>
                                <td className="text-center">
                                    {isEditable ? (
                                        <input
                                            defaultValue={item.amount}
                                            contentEditable='true'
                                            id="amount"
                                            className="form-control t-12"
                                            type="number"
                                            placeholder={item.amount}
                                            onChange={(e) => {
                                                let newLineData = lineItemList;
                                                newLineData[index].amount = Number(e.target.value)
                                                setLineItemList(newLineData);
                                            }}
                                        ></input>
                                    ) : convertAmountWithDecimal(item.amount)}
                                </td>
                                <td className="text-center">
                                    {isEditable ? (
                                            <input
                                                defaultValue={sgst}
                                                contentEditable='true'
                                                id="sgst"
                                                className="form-control t-12"
                                                type="number"
                                                placeholder={cgst}
                                                onChange={(e) => {
                                                    let newLineData = lineItemList;
                                                    newLineData[index].sgst =  Number(e.target.value)
                                                    setLineItemList(newLineData);
                                                }}
                                            ></input>
                                    ) : sgst}
                                </td>
                                <td className="text-center">
                                    {isEditable ? (
                                                <input
                                                    defaultValue={cgst}
                                                    contentEditable='true'
                                                    id="cgst"
                                                    className="form-control t-12"
                                                    type="number"
                                                    placeholder={igst}
                                                    onChange={(e) => {
                                                        let newLineData = lineItemList;
                                                        newLineData[index].cgst =  Number(e.target.value)
                                                        setLineItemList(newLineData);
                                                    }}
                                                ></input>
                                        ) : cgst}
                                </td>
                                <td className="text-center">
                                    {isEditable ? (
                                                <input
                                                    defaultValue={igst}
                                                    contentEditable='true'
                                                    id="igst"
                                                    className="form-control t-12"
                                                    type="number"
                                                    placeholder={igst}
                                                    onChange={(e) => {
                                                        let newLineData = lineItemList;
                                                        newLineData[index].igst =  Number(e.target.value)
                                                        setLineItemList(newLineData);
                                                    }}
                                                ></input>
                                        ) : igst}
                                </td>

                                <td className="text-center" hidden={isNewLineItemEnabled || editableRow}>
                                    {convertAmountWithDecimal(item.amount + item.tax_amount)}
                                </td>

                                <td className="text-center">
                                <i
                                    className="mt-2 fa fa-remove color-blue cursor-pointer"
                                    title="Remove Row"
                                    onClick={(e) => {
                                    lineItemList[index].discard = true;
                                    if(get(lineItemList,`[${index}].id`, false)) {
                                        setDeletedLines([
                                            ...deletedLines,
                                            get(lineItemList,`[${index}].id`, "")
                                        ])
                                    }
                                    let newList = lineItemList.filter(
                                        (item) => !item.discard
                                    );
                                    setLineItemList([...newList]);
                                    }}
                                ></i>
                                </td>
                            </tr>
                            );
                        })}
                        {isNewLineItemEnabled && (
                            <tr>
                            <td>
                                <input
                                id="description"
                                className="form-control t-12"
                                type="text"
                                onChange={(e) => {
                                    setModifiedNewLineItemRow(
                                    e.target.id,
                                    e.target.value
                                    );
                                }}
                                ></input>
                            </td>
                            <td>
                                <input
                                id="amount"
                                className="form-control t-12"
                                type="number"
                                onChange={(e) => {
                                    setModifiedNewLineItemRow(
                                    e.target.id,
                                    e.target.value
                                    );
                                }}
                                ></input>
                            </td>
                            <td>
                                <input
                                id="sgst"
                                className="form-control t-12"
                                type="number"
                                onChange={(e) => {
                                    setModifiedNewLineItemRow(
                                    e.target.id,
                                    e.target.value
                                    );
                                }}
                                ></input>
                            </td>
                            <td>
                                <input
                                id="cgst"
                                className="form-control t-12"
                                type="number"
                                onChange={(e) => {
                                    setModifiedNewLineItemRow(
                                    e.target.id,
                                    e.target.value
                                    );
                                }}
                                ></input>
                            </td>
                            <td>
                                <input
                                id="igst"
                                className="form-control t-12"
                                type="number"
                                onChange={(e) => {
                                    setModifiedNewLineItemRow(
                                    e.target.id,
                                    e.target.value
                                    );
                                }}
                                ></input>
                            </td>
                            <td hidden={isNewLineItemEnabled}>
                                <input
                                id="final_amount"
                                className="form-control t-12"
                                type="number"
                                onChange={(e) => {
                                    setModifiedNewLineItemRow(
                                    e.target.id,
                                    e.target.value
                                    );
                                }}
                                ></input>
                            </td>
                            <td className="text-center">
                                <i
                                className="fa fa-save color-blue cursor-pointer"
                                title="Save Row"
                                onClick={() => {
                                    let description = get(
                                    newLineItemRow,
                                    "description",
                                    ""
                                    ).trim();
                                    let cgst = get(
                                        newLineItemRow,
                                        "cgst",
                                        ""
                                        ).trim();
                                    let igst = get(
                                        newLineItemRow,
                                        "igst",
                                        ""
                                        ).trim();
                                    let sgst = get(
                                        newLineItemRow,
                                        "sgst",
                                        ""
                                        ).trim();
                                    let amount = get(
                                    newLineItemRow,
                                    "amount",
                                    ""
                                    ).trim();
                                    if (
                                    description === "" ||
                                    amount === ""
                                    ) {
                                        toast.error("Please fill required fields")
                                    } else {
                                    let newData = {
                                        description,
                                        amount: parseFloat(amount),
                                        tax_amount: (cgst/100 * amount) + (igst/100 * amount) + (sgst/100 * amount),
                                        taxes: `cgst=${parseFloat(cgst)},igst=${parseFloat(igst)},sgst=${parseFloat(sgst)}`
                                    };
                                    setLineItemList([...lineItemList, newData]);
                                    setNewLineItemRow({});
                                    setIsNewLineItemEnabled(false);
                                    }
                                }}
                                ></i>
                                <i
                                className="m-2 fa fa-remove color-blue cursor-pointer"
                                title="Remove Row"
                                onClick={() => {
                                    document.getElementById("description").value = null;
                                    document.getElementById("cgst").value = null;
                                    document.getElementById("igst").value = null;
                                    document.getElementById("sgst").value = null;
                                    document.getElementById("amount").value = null;
                                    setIsNewLineItemEnabled(false);
                                }}
                                ></i>
                            </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                    
                    <span
                    className="t-14 color-blue cursor-pointer"
                        onClick={() => setIsNewLineItemEnabled(true)}
                    >
                    Add Row*
                    </span>
                    </ClickAwayListener>
                    <hr className="mb-0 mt-4"></hr>
                    <div>
                    <div className="row mt-2">
                        <div className="col-md-2">
                        <div className="t-14 font-weight-bold mb-1">
                            Subtotal: {convertAmountWithDecimal(subtotal)}
                        </div>
                        <div className="t-14 text-right mb-1">
                            Tax Total: {convertAmountWithDecimal(
                                lineItemList.length ?
                                lineItemList.map(line => get(line,"tax_amount",0)).reduce((total, num) => total + num)
                                : 0
                            )}
                        </div>
                        <div className="t-14 font-weight-bold">
                            Total: {convertAmountWithDecimal(
                                lineItemList.length 
                                ? subtotal + lineItemList.map(line => get(line,"tax_amount",0)).reduce((total, num) => total + num)
                                : 0
                            )}
                        </div>
                        </div>
                    </div>
                    </div>
                    {label === "Update Invoice" ? <>
                        <hr/>
                        <div className="mt-2">
                            <FileUpload
                                onUploadFiles={(files) => {
                                    setFiles(files)
                                }}
                            />
                        </div> 
                    </>: ""}
                </div>
                </div>
            )}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="col-md-12">
                <div className="pull-right m-2">
                <button
                    className="btn btn-primary"
                    disabled={!lineItemList.length 
                        || !get(postdata,"header_note","")
                        || !get(postdata,"invoice_date","")
                        || get(postdata,"invoice_date","") === "Invalid date"
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                        setSaving(true);
                        let postObj = {
                            ...postdata,
                        };

                        if(!get(postdata,"due_date","") || get(postdata,"due_date","") === "Invalid date") {
                            postObj = omit(postObj,["due_date"])
                        }

                        if(lineItemList.length) {
                            postObj["lines"] = lineItemList.map(line => {
                                var taxes = get(line,"taxes","") ? get(line,"taxes","").split(",") : "";
                                var cgst = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
                                var igst = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
                                var sgst = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;
                                
                                let obj = {
                                    description: line.description,
                                    amount: line.amount,
                                    cgst,
                                    igst,
                                    sgst
                                }
                                if(get(line,"id",false))
                                    obj["id"] = get(line,"id","")
                                
                                return obj;
                            })
                        }

                        if(deletedLines.length)
                            removeLines(invoiceId, deletedLines);

                        (label === "Update Invoice" 
                            ? updateInvoice(invoiceId, postObj, true, () => {
                                if(files)
                                    updateInvoice(invoiceId, files, false, () => {
                                        refreshPage();
                                    }, "Could not save attached file");

                                refreshPage();
                            }) 
                            : createInvoice(window.location.pathname.split("/")[2], postObj, true, () => {
                                if(files)
                                    createInvoice(window.location.pathname.split("/")[2], files, false, () => {}, "Could not save attached file");

                                refreshPage();
                            })
                        )
                    }}
                >
                    {saving ? <><i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;</> : "" }Submit
                </button>
                <button
                    className="btn btn-secondary m-2"
                    onClick={() => {
                        setInvoiceData({
                            showModal: false,
                            invoiceInfo: ""
                        }, "CUSTOMER_DETAILS")
                    }}
                >
                    Cancel
                </button>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
    invoiceDetails: state.dynamicformReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewInvoice);