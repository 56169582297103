import { ReducersTypes } from "../../constants/reducerTypes";
import moment from "moment";

export const initialCustomerDetailsFilters = {
  from_date: moment().subtract(2, "year").format("DD/MM/YYYY"),
  to_date: moment().add(1, "year").format("DD/MM/YYYY"),
  not_tag_ids: [],
  page: 1,
  label: "",
  per_page: 20,
  sort_by: `total_amount_due`,
  sort_order: "desc",
  selectedSection: null,
  showFilter: false,
  status: "all"
};

export const initialCustomerDetails = {
  filter: {
    ...initialCustomerDetailsFilters,
  },
  dates: {},
  showModal: false,
  showModal2: false,
  selectedSection: null,
  chartData: {},
  invoiceList: [],
  selectedRows: [],
  customerData: null,
  isFetching: false,
  fetchingChartData: false,
  exportedData: [],
  showexport: false,
  exporting: false,
  invoiceInfo: {},
  resetCustomerFilter: false,
};

const customerDetails = (state = initialCustomerDetails, action) => {
  switch (action.type) {
    case ReducersTypes.CUSTOMER_DETAILS:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_CUSTOMER_DETAILS:
      return initialCustomerDetails;
    case ReducersTypes.CUSTOMER_DETAILS_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } };
    case ReducersTypes.RESET_CUSTOMER_DETAILS_FILTER:
      return { ...state, filter: initialCustomerDetailsFilters, resetCustomerFilter: !state.resetCustomerFilter };
    default:
      return state;
  }
};

export default customerDetails;