import { combineReducers } from "redux";
import customerList from "./containers/customerDashboard/reducer";
import customerDetails from "./containers/customerDetails/reducer";
import globalReducers from "./reducers/global.js"
// Combine all reducers.
const appReducer = combineReducers({
  customerList,
  ...globalReducers,
  customerDetails,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") state = undefined;

  return appReducer(state, action);
};

export default rootReducer;