import React, { Component } from "react";
// import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import NavWrapper from "./navWrapper";
import TokenManager from "../utils/TokenManager";
import login from '../containers/login'
import { getNewToken } from "../utils/token";
import { store } from "../store";
import { Toaster } from 'react-hot-toast';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={(props) =>
        TokenManager.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      {...rest}
    />
  );
};
const refreshTokenTimeout = 300000; // 5 mins
class Root extends React.Component{
    constructor(props) {
      super(props);
      this.events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
      ];
      this.setRefreshTimeout = this.setRefreshTimeout.bind(this);
      this.lastUserAction = null;
      this.minTimeout = null;
      this.lastEventTime = null;
      this.warnTimeout = null;
      this.isTokenRefreshedOnce = false;
      window.addEventListener("storage", this.storageChange, false);
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.setRefreshTimeout);
      }
    }

    componentDidMount() {
      document.title = "Finerv";
      if(TokenManager.isLoggedIn()) {
        this.setRefreshTimeout();
      }
    }

    refreshToken = (refresh) => {
      getNewToken()
        .then((response) => {
          TokenManager.set(
            response.data.token,
            response.data["expires at"]
          );
          TokenManager.setUserData(response.data);
          this.clearSetTimeout();
          this.setRefreshTimeout();
      }).catch(e => {
          localStorage.clear();
          store.dispatch({ type: 'DESTROY_SESSION' });
          window.location.reload();
      })
    }

    setRefreshTimeout = (flag) => {
      const tokenExpiryTime = TokenManager.getExpiredAt();
      if (!tokenExpiryTime) {
        return;
      }
      const currentTime = Math.round(new Date().getTime());
      this.lastEventTime = currentTime;
      
      const REFRESH_TOKEN_TIME = refreshTokenTimeout; // refresh before 5 mins
      const warnTime = tokenExpiryTime - currentTime - REFRESH_TOKEN_TIME;

      if (!this.warnTimeout) {
        this.warnTimeout = setTimeout(() => {
          this.refreshToken(true);
          this.isTokenRefreshedOnce = true;
        }, warnTime);
      }
    };

    clearSetTimeout() {
      if (this.warnTimeout) {
        clearTimeout(this.warnTimeout);
        this.warnTimeout = null;
      }
    }

    componentWillUnmount() {
      this.destroy();
    }
  
    destroy() {
      this.clearSetTimeout();
  
      for (var i in this.events) {
        window.removeEventListener(this.events[i], this.resetTimeout);
      }
    }

    render () {
      return (
        <div className="">
          <Toaster/>
          <Router>
            <Switch>
              <Route path="/login" component={login} />
              <Route
                exact path="/forgot-password"
                render={(props) => <></>}
              />
              <ProtectedRoute path="/" component={NavWrapper} />
            </Switch>
          </Router>
        </div>
      )
    }
}

// const mapStateToProps = (state) => ({
//   refresh_header: state.header.refresh_header,
// });

// const mapDispatchToProps = {};

export default Root;