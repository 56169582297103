import React, { useState, useRef } from "react";
import html2pdf from 'html2pdf.js';
import { convertAmountWithDecimal } from "../utils/amount";
import { Button, Modal } from "react-bootstrap";
import { get } from "lodash";
import moment from "moment";
import TokenManager from "../utils/TokenManager";
import { exportHTMLPdf } from "../utils";
import signature from "../assets/images/Signature.jpg"
import ReactToPrint from "react-to-print";

export const exportPdfInvoice = (filename,cb) => {
    exportHTMLPdf("#INV_TEMP", filename, true, () => cb())
}

function RenderTemplate ({
    setInvoiceData,
    customerDetails: {
        showModal2,
        invoiceList
    },
    selectedInvoices,
    clientDetails,
}) {
    const [isDownloading, setIsDownloading] = useState(false);
    const componentRef = useRef();
  return (
    <>
    <div
        className="d-flex align-items-center justify-content-center"
        >
        <Button variant="primary" onClick={() => {
             setInvoiceData({
                showModal2: true,
            }, "CUSTOMER_DETAILS")
        }}><i class="fa fa-download" aria-hidden="true"></i>&nbsp;&nbsp;Download Invoice</Button>
    </div>
    <Modal show={showModal2} onHide={() => {
        setInvoiceData({
            showModal2: false,
        }, "CUSTOMER_DETAILS")
    }} size="lg">
        {/*<Modal.Header>
            {/* <Button variant="primary" onClick={() => {
                setIsDownloading(true);
                {selectedInvoices.length > 1 ? exportPdfInvoice(`INV_${moment().format("DD/MM/YYYY")}`,() => setIsDownloading(false)) : exportPdfInvoice(`INVOICE_${selectedInvoices[0].invoice_number}`,() => setIsDownloading(false))};
            }}><i className={`${isDownloading ? "fa fa-spinner fa-spin" : "fa fa-download"}`} aria-hidden="true"></i>
                &nbsp;Download Invoice
            </Button> 
            <ReactToPrint
                trigger={() => <Button variant="primary" ><i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Invoice</Button>}
                content={() => componentRef.current}
            />
        </Modal.Header>
        <Modal.Body>
            <div ref={componentRef} className="invoiceTemplate" id="invoiceTemplate" style={{fontFamily: "Open Sans", fontSize: 15, color: "#000", backgroundColor: "#fff",  width: "100%", boxSizing: "border-box"}}>
                {selectedInvoices.map((invoice,idx) => {
                    const customer = get(invoice, "customer", {})
                    return (
                        <div id={`INV_TEMP`} className="p-3 page-break" >
                        <div className="clientSection m-2 row">
                            <div>
                                <img 
                                    src={`https://devbe-finista.onrender.com/api/client/logo?data=${TokenManager.isLoggedIn()}`}
                                    alt="Company Logo"
                                />
                            </div>
                            <div>
                                <div className="fs-5"><strong>{get(invoice,"invoice_type","-")}</strong></div>
                                <div style={{fontSize: 13}}>{get(invoice,"invoice_type")==="PROFORMA INVOICE"? "PI NO:" : "INVOICE NO:"} {invoice.invoice_number}</div>
                                <div style={{fontSize: 13}}>DATE: {moment(get(invoice,"invoice_date","")).format("DD/MM/YYYY")}</div>
                            </div>
                        </div>

                        <div className="customerSection m-3">
                            <div>
                                <div><b>BILL TO</b></div>
                                <div>{get(invoice,"passenger_name") ? get(invoice,"passenger_name"): get(customer,"name", "")}</div>
                                <div>{get(invoice,"passenger_address") ? get(invoice,"passenger_address"): get(customer, "physical_address", "")}</div>
                                <div>{get(invoice,"passenger_state") ? get(invoice,"passenger_state"):get(customer, "state", "")}-{get(invoice,"passenger_pincode") ? get(invoice,"passenger_pincode"):get(customer, "pincode", "")}</div>
                                <div>GSTIN: {get(customer, "gstin")? get(customer, "gstin") : "NA"}</div>
                                <div>Contact: {get(invoice,"passenger_contact") ? get(invoice,"passenger_contact"): (get(customer, "contact_person_email")? get(customer, "contact_person_email"): "NA")}</div>
                            </div>
                            <div>
                                <div>{get(clientDetails,"name","")}</div>
                                <div>{get(clientDetails,"address","")}</div>
                                <div>{get(clientDetails, "state", "")}-{get(clientDetails, "pincode", "")}</div>
                                <div>GSTIN: {get(clientDetails,"gstin")? get(clientDetails,"gstin"): "NA"}</div>
                                <div>Web: {get(clientDetails, "website")? get(clientDetails, "website"): "NA"}</div>
                            </div>
                        </div>

                        <div className="m-3">
                            <div style={{
                                whiteSpace: "initial",
                                overflowWrap: "break-word"
                            }}>Description: {invoice.header_note}</div>
                        </div>
                        <div className="lineItemsSection m-3">
                            <table className="mt-3"style={{border: '3px solid black'}}>
                                <thead>
                                <tr>
                                    <td >ID</td>
                                    <td style={{width: "52%"}}>DESCRIPTION</td>
                                    <td >AMOUNT</td>
                                    <td >SGST</td>
                                    <td >CGST</td>
                                    <td >IGST</td>
                                    <td>FINAL AMOUNT</td>
                                </tr>
                                </thead>
                                <tbody>
                                {get(invoice,"invoice_lines",[]).map((inv, idx) => {
                                    var taxes = get(inv,"taxes","") ? get(inv,"taxes","").split(",") : "";
                                    var cgst = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
                                    var igst = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
                                    var sgst = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;
                                    return <tr>
                                        <td>{idx+1}</td>
                                        <td style={{textAlign: "left"}}>{inv.description}</td>
                                        <td>{inv.amount}</td>
                                        <td>{convertAmountWithDecimal(inv.amount*(sgst/100))} ({sgst}%)</td>
                                        <td>{convertAmountWithDecimal(inv.amount*(cgst/100))} ({cgst}%)</td>
                                        <td>{convertAmountWithDecimal(inv.amount*(igst/100))} ({igst}%)</td>
                                        <td>{convertAmountWithDecimal(inv.amount + inv.tax_amount)}</td>
                                    </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="footerSection m-3 d-flex">
                            <div className="col-md-8">
                                <table>
                                    <thead>
                                        <tr>
                                            <td className="w-3"></td>
                                            <td style={{fontWeight: "bold", textAlign: "center"}}>OTHER COMMENTS</td>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td style={{textAlign: "center"}}>1</td>
                                            <td>100% Payment within 07 working days of submission of Invoice.</td>
                                        </tr>
                                        <tr>
                                            <td style={{textAlign: "center"}}>2</td>
                                            <td>Payment should be effected by Bank Transfer/Cheque in favour of M/s Lets Unpanic.</td>
                                        </tr>
                                        <tr>
                                            <td style={{textAlign: "center"}}>3</td>
                                            <td>All disputes are subject to Delhi Jurisdiction only.</td>
                                        </tr>
                                        <tr>
                                            <td style={{textAlign: "center"}}></td>
                                            <td style={{lineHeight: 0.1}}>
                                                <hr className="mt-1"></hr>
                                                <p><b>Bank Details:</b></p>
                                                <p>Beneficiary Name:&nbsp;<b>Lets Unpanic</b>&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;Bank Name:&nbsp;<b>State Bank of India</b></p>
                                                <p>Account No: <b>39723017721</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;IFSC Code: <b>SBIN0011558</b></p>
                                                <p>Address: <b>Yamuna Vihar Delhi 110094</b>&emsp;&emsp;&nbsp; Swift Code: <b>SBININBB542</b></p>
                                                <p>GSTIN No.: <b>07AAIFL9412D1ZF </b>&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; PAN No.: <b>AAIFL9412D</b></p>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-3" style={{textAlign: "right", fontSize: 14}}>
                                <div className="mt-1 row">
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-5" style={{textAlign: "right", paddingRight: 0, fontWeight: "bold"}}>
                                            <div>Subtotal :</div>
                                            <div>Tax Total:</div>
                                            <hr style={{marginTop: 1.5, marginBottom: 1}} />
                                            <div>Net Total:</div>
                                    </div>
                                    <div className="col-md-5" style={{textAlign: "right", paddingLeft: 0}}>
                                        <div>{convertAmountWithDecimal(invoice.invoice_lines.map(line => get(line,"amount",0)).reduce((total, num) => total + num))}</div>
                                        <div>{convertAmountWithDecimal(invoice.invoice_lines.map(line => get(line,"tax_amount",0)).reduce((total, num) => total + num))}</div>
                                        <hr style={{marginTop: 1.5, marginBottom: 1}} />
                                        <div>{convertAmountWithDecimal(invoice.invoice_lines.map(line => get(line,"amount",0)).reduce((total, num) => total + num) + invoice.invoice_lines.map(line => get(line,"tax_amount",0)).reduce((total, num) => total + num))}</div>
                                    </div>
                                </div>
                                <br></br>
                                <div style={{textAlign: "center"}}>FOR {get(clientDetails,"name","")}</div>
                                <br></br>
                                <div>
                                <img 
                                    src={signature}
                                    alt="Company Logo"
                                />
                                </div>
                                <div>Authorised Signatory</div>
                            </div>
                        </div>
                        <div className="Section mt-3 text-center">
                            <div><i>Thank You For Choosing Us!!</i></div>
                            <br></br>
                            <div>This Invoice is system generated and does not require any seal/signature in original</div>       
                        </div>
                        <hr/>
                    </div>
                    )
                })}
            </div>
            </Modal.Body>*/}
        <Modal.Header>
    <ReactToPrint
        trigger={() => <Button variant="primary"><i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Invoice</Button>}
        content={() => componentRef.current}
    />
</Modal.Header>
<Modal.Body>
    <div ref={componentRef} className="bg-white px-3 py-2 text-black" style={{fontFamily: "Times New Roman"}}>
        {selectedInvoices.map((invoice, idx) => {
            const customer = get(invoice, "customer", {});
            return (
                <div key={`invoice_${idx}`} className="page-break">
                    <div className="flex justify-between items-center mb-3">
                        <div className="w-1/2">
                            <img src={`https://devbe-finista.onrender.com/api/client/logo?data=${TokenManager.isLoggedIn()}`} alt="Company Logo" />
                        </div>
                        <div className="w-1/4 text-left">
                            <div className="text-lg font-bold">{get(invoice,"invoice_type","-")}</div>
                            <div className="text-sm">{get(invoice,"invoice_type")==="PROFORMA INVOICE"? "PI NO:" : "INVOICE NO:"} {invoice.invoice_number}</div>
                            <div className="text-sm">DATE: {moment(get(invoice,"invoice_date","")).format("DD/MM/YYYY")}</div>
                        </div>
                    </div>

                    <div className="mb-4 flex justify-between">
                        <div className="w-1/2">
                            <div><b>BILL TO</b></div>
                            <div>{get(invoice,"passenger_name") ? get(invoice,"passenger_name"): get(customer,"name", "")}</div>
                            <div>{get(invoice,"passenger_address") ? get(invoice,"passenger_address"): get(customer, "physical_address", "")}</div>
                            <div>{get(invoice,"passenger_state") ? get(invoice,"passenger_state"):get(customer, "state", "")}-{get(invoice,"passenger_pincode") ? get(invoice,"passenger_pincode"):get(customer, "pincode", "")}</div>
                            <div>GSTIN: {get(customer, "gstin")? get(customer, "gstin") : "NA"}</div>
                            <div>Contact: {get(invoice,"passenger_contact") ? get(invoice,"passenger_contact"): (get(customer, "contact_person_email")? get(customer, "contact_person_email"): "NA")}</div>
                        </div>
                        <div className="w-1/4 text-left text-wrap">
                            <div>{get(clientDetails,"name","")}</div>
                            <div>{get(clientDetails,"address","")}</div>
                            <div>{get(clientDetails, "state", "")}-{get(clientDetails, "pincode", "")}</div>
                            <div>GSTIN: {get(clientDetails,"gstin")? get(clientDetails,"gstin"): "NA"}</div>
                            <div>Web: {get(clientDetails, "website")? get(clientDetails, "website"): "NA"}</div>
                            <div>Email: ajay@letsunpanic.com</div>
                            <div>Phone: +91-9811486068</div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="text-md">Description: {invoice.header_note}</div>
                    </div>

                    <div className="mb-4">
                        <table className="w-full border-collapse border border-black bg-white">
                            <thead className="bg-white">
                                <tr className="font-sans text-center text-sm">
                                    <th className="border-2 border-black">ID</th>
                                    <th className="border-2 border-black w-1/2 py-0">DESCRIPTION</th>
                                    <th className="border-2 border-black">AMOUNT</th>
                                    <th className="border-2 border-black">SGST</th>
                                    <th className="border-2 border-black">CGST</th>
                                    <th className="border-2 border-black">IGST</th>
                                    <th className="border-2 border-black">FINAL AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                            {get(invoice,"invoice_lines",[]).map((inv, idx) => {
                                    var taxes = get(inv,"taxes","") ? get(inv,"taxes","").split(",") : "";
                                    var cgst = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
                                    var igst = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
                                    var sgst = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;
                                    return <tr className="text-md">
                                        <td className="border-2 border-black text-center">{idx+1}</td>
                                        <td className="border-2 border-black text-left px-0 mx-0">{inv.description}</td>
                                        <td className="border-2 border-black">{inv.amount}</td>
                                        <td className="border-2 border-black">{sgst}%</td>
                                        <td className="border-2 border-black">{cgst}%</td>
                                        <td className="border-2 border-black">{igst}%</td>
                                        <td className="border-2 border-black text-right">{convertAmountWithDecimal(inv.amount + inv.tax_amount)}</td>
                                    </tr>
                                    })}
                                {/* {get(invoice, "invoice_lines", []).map((inv, idx) => {
                                    var taxes = get(inv,"taxes","") ? get(inv,"taxes","").split(",") : "";
                                    var cgst = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
                                    var igst = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
                                    var sgst = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;

                                    <tr key={`line_${idx}`}>
                                        <td className="border border-black">{idx + 1}</td>
                                        <td className="border border-black text-left">{inv.description}</td>
                                        <td className="border border-black">{inv.amount}</td>
                                        <td className="border border-black">{convertAmountWithDecimal(inv.amount * (sgst / 100))} ({sgst}%)</td>
                                        <td className="border border-black">{convertAmountWithDecimal(inv.amount * (cgst / 100))} ({cgst}%)</td>
                                        <td className="border border-black">{convertAmountWithDecimal(inv.amount * (igst / 100))} ({igst}%)</td>
                                        <td className="border border-black">{convertAmountWithDecimal(inv.amount + inv.tax_amount)}</td>
                                    </tr>
                                    })} */}
                            </tbody>
                        </table>
                    </div>


                    <div className="mb-2 flex flex-row justify-between">
                        <div className="w-2/3 ">
                            <div className="border-2 border-gray-900 border-solid p-1">
                                <div className="text-center border-b-2 border-gray-900"><b>OTHER COMMENTS</b></div>
                                <div className="text-wrap">1. 100% Payment within 07 working days of submission of Invoice.</div>
                                <div className="text-wrap">2. Payment should be effected by Bank Transfer/Cheque in favour of M/s Lets Unpanic.</div>
                                <div className="text-wrap">3. All disputes are subject to Delhi Jurisdiction only.</div>
                                <div>
                                    <hr className="mt-1"></hr>
                                    <p><b>Bank Details:</b></p>
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <p>Beneficiary Name:&nbsp;<b>Lets Unpanic</b></p>
                                            <p>Account No: <b>39723017721</b></p>
                                            <p>Address: <b>Yamuna Vihar Delhi 110094</b></p>
                                            <p>GSTIN No.: <b>07AAIFL9412D1ZF </b></p>
                                        </div>
                                        <div>
                                            <p>Bank Name: <b>State Bank of India</b></p>
                                            <p>IFSC Code: <b>SBIN0011558</b></p>
                                            <p>Swift Code: <b>SBININBB542</b></p>
                                            <p>PAN No.: <b>AAIFL9412D</b></p>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex flex-col items-end">
                                <div className="flex justify-between items-center">
                                    <div className="mt-1 flex flex-col items-end text-left">
                                        <div className="font-bold pr-6">Subtotal :</div>
                                        <div className="font-bold pr-6">Tax Total:</div>
                                        <hr className="mt-2 border-gray-900 w-full"></hr>
                                        <div className="font-bold pr-6">Net Total:</div>
                                    </div>
                                    <div className="mt-1 flex flex-col items-end text-right">
                                        <div>{convertAmountWithDecimal(invoice.invoice_lines.map(line => get(line,"amount",0)).reduce((total, num) => total + num))}</div>
                                        <div>{convertAmountWithDecimal(invoice.invoice_lines.map(line => get(line,"tax_amount",0)).reduce((total, num) => total + num))}</div>
                                        <hr className="mt-2 border-gray-900 w-full"></hr>
                                        <div>{convertAmountWithDecimal(invoice.invoice_lines.map(line => get(line,"amount",0)).reduce((total, num) => total + num) + invoice.invoice_lines.map(line => get(line,"tax_amount",0)).reduce((total, num) => total + num))}</div>
                                    </div>
                                </div>
                                <div className="mt-2" style={{textAlign: "center"}}>FOR {get(clientDetails,"name","")}</div>
                                <br></br>
                                <div>
                                    <img src={signature} alt="Company Logo" />
                                </div>
                                <div>Authorised Signatory</div>
                            </div>
                        </div>
                    </div>

                    <div className="Section mb-1 text-center">
                        <div><i>Thank You For Choosing Us!!</i></div>
                        <div>This Invoice is system generated and does not require any seal/signature in original</div>
                    </div>

                    <hr className="mb-1"></hr>
                </div>
            )
        })}
    </div>
</Modal.Body>

    </Modal>
    </>
  );
};

export default RenderTemplate;