import React from "react";
import Spinner from "react-bootstrap/Spinner";

export const CustomSpinner = ({ isfullscreen, classNames = '' }) => {
  return (
    <div className={`card-body w-100 text-center pt-5 mt-5 ${isfullscreen && "vh-100"} ${classNames}`}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CustomSpinner;