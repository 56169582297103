import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { get } from "lodash";
import instance from "../../axios";
import { dataURItoBlob } from "../../utils";
import { getNewToken } from "../../utils/token";
import TokenManager from "../../utils/TokenManager";
import "../../assets/css/profile.css"
import { Button } from "react-bootstrap";

function Profile() {

  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [userDetails, setUserDetails] = useState({
    name: "",
    username: "",
    password: ""
  })

  useEffect(() => {
    getUserData();
  },[]);

  const getUserData = () => {
    setImage({
      data: `https://devbe-finista.onrender.com/api/client/logo?data=${TokenManager.isLoggedIn()}`
    })
    instance.get(`client/user?id=${TokenManager.getUserId()}`)
      .then(res => {
        setUserDetails(res.data.result[0]);
        setUploading(false);
      })
  }

  const handleImageChange = (e) => {
    const {files} = e.target;
    const file = files[0];

    if(!file)
      return;

    const size = file.size / 1024;
    var reader = new FileReader();
    
    if (size > 100) {
      toast.error("Image size should be less than 100kb")
      return;
    }
    var canvas = document.getElementById("cv");
    var ctx = canvas.getContext("2d");
    //Read the contents of Image File.
    reader.readAsDataURL(files[0]);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        const canvas = ctx.canvas;
        const hRatio = canvas.width / width;
        const vRatio = canvas.height / height;
        const ratio = Math.min(hRatio, vRatio);
        const centerShift_x = (canvas.width - width * ratio) / 2;
        const centerShift_y = (canvas.height - height * ratio) / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          image,
          0,
          0,
          width,
          height,
          centerShift_x,
          centerShift_y,
          width * ratio,
          height * ratio
        );
        setImage({name: file.name, data: canvas.toDataURL("image/png")});
      };
    };
  };

  const handleSubmit = () => {
    setUploading(true);
    instance
      .put(`client/user?id=${TokenManager.getUserId()}`, JSON.stringify(userDetails))
      .then(() => {
        if(password)
          getNewToken()
              .then((response) => {
                toast.success("Profile updated")
                TokenManager.set(
                  response.data.token,
                  response.data["expires at"]
                );
                TokenManager.setUserData(response.data);
                getUserData();
            }).catch(e => {
              toast.error("An error occurred")
              setUploading(false);
            });
          
          TokenManager.setUserData({
            ...JSON.parse(TokenManager.getUserData()),
            username: username,
            name: name
          })
          toast.success("Profile updated")
          getUserData();
      })
      .catch((e) => {
        toast.error("An error occurred");
        setUploading(false);
      });

    if (image.name && image.name !== "null") {
      let bodyFormData = new FormData();
      bodyFormData.append(
        "logo",
        new File([dataURItoBlob(image.data)], image.name),
        `${image.name}`
      );
      instance
        .post("client", bodyFormData)
        .then(() => {
          toast.success("Profile updated")
        })
        .catch((e) => toast.error("An error occurred"));
    }
  };

  const {
    name,
    username,
    password
  } = userDetails;
 
  return (
    <div className="profile">
      <div>
        <img
          src={get(image,"data","")}
          alt={(TokenManager.getUserName()[0]).toUpperCase()}
          className="fs-1"
          title="Click here to upload logo"
          style={{width: "fit-content", height: "20vh", objectFit: "cover"}}
        ></img>
        <Button className="m-4" onClick={() => {
          document.getElementById("file").click();
        }}>
          <i className="fa fa-upload"/>&nbsp;&nbsp;Company Logo
        </Button>
        <input
          type="file"
          id="file"
          className="logo-file-element d-none"
          title="Upload logo"
          accept="image/png, image/jpeg"
          name="logo"
          onChange={handleImageChange}
        />
        <canvas
          id="cv"
          height="100"
          width="200"
          className="d-none"
        />
      </div>
      <div className="col-md-8 mb-4">
          <strong><i style={{color: "#2a83dc"}} className="fa fa-user fs-5"/>&nbsp;&nbsp;Name</strong>
          <div className="form-group mt-2">
              <div className="">
                  <input
                  style={{height: "50px"}}
                  placeholder="Enter name"
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setUserDetails({
                    ...userDetails,
                    name: e.target.value
                  })}
                  />
              </div>
          </div>
          <span className="form-error" hidden={name}>
              Name is required
          </span>
      </div>
      <div className="col-md-8 mb-4">
          <strong><i style={{color: "#2a83dc"}} className="fa fa-user fs-5"/>&nbsp;&nbsp;User Name</strong>
          <div className="form-group mt-2">
              <div className="">
                  <input
                  style={{height: "50px"}}
                  placeholder="Enter username"
                  type="text"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUserDetails({
                    ...userDetails,
                    username: e.target.value
                  })}
                  />
              </div>
          </div>
          <span className="form-error" hidden={username}>
              User Name is required
          </span>
      </div>
      <div className="col-md-8 mb-4">
          <strong><i style={{color: "#2a83dc"}} className="fa fa-key fs-5"/>&nbsp;&nbsp;Password</strong>
          <div className="form-group mt-2">
              <div className="">
                  <input
                  style={{height: "50px"}}
                  placeholder="Enter password"
                  type="text"
                  className="form-control"
                  value={password}
                  onChange={(e) => setUserDetails({
                    ...userDetails,
                    password: e.target.value
                  })}
                  />
              </div>
          </div>
      </div>
      <Button 
        onClick={handleSubmit}
        disabled={!name || !username || uploading}
      >
          {uploading ? <><i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;</> : ""} Submit
      </Button>
    </div>
  );
}

export default Profile;