import get from "lodash/get";

export default class TokenManager {

    static set(idToken, expired_at_api_time) {
        if (idToken) {
            const expired_at_in_seconds = new Date(expired_at_api_time).getTime();
            localStorage.setItem("expiredat", expired_at_in_seconds);
            localStorage.setItem("authToken", idToken);
        }
    }

    static getExpiredAt() {
        return localStorage.getItem("expiredat")
          ? JSON.parse(localStorage.getItem("expiredat"))
          : 0;
    }
    
    static isLoggedIn() {
        return localStorage.getItem("authToken");
    }

    static setUserData(userData) {
        if (userData) {
          localStorage.setItem("loggedInUserData", JSON.stringify(userData));
        }
      }

    static getUserData() {
        return localStorage.getItem("loggedInUserData") || "{}";
    }

    static getUserName() {
        return JSON.parse(TokenManager.getUserData())["username"];
    }

    static getUserId() {
        return JSON.parse(TokenManager.getUserData())["id"];
    }

    static getRoles() {
        const loggedInUserData = JSON.parse(TokenManager.getUserData());
        return get(loggedInUserData, "roles", []);
    }

    static getCurrencyCode() {
        return "INR";
    }
    
}