import instance from "../../axios.js";
import get from "lodash/get";
import { ReducersTypes } from "../../constants/reducerTypes";
import { generateQueryString } from "../../utils/query.js";
import moment from "moment";
import toast from "react-hot-toast";

const setData = (type, data) => ({
  type,
  payload: {
    ...data,
  },
});

export const exportData = (params) => async (dispatch) => {
  let URL = await getQuery(params, "invoice");
  dispatch(setData(ReducersTypes.CUSTOMER_DETAILS, { exporting: true }));
  return instance.get(URL);
};

const getQuery = async (data, URL) => {
  var query = URL;
  return `${query}${generateQueryString(data)}`;
};

export const createInvoice = (id, postObj = {}, parse = true, callback = () => {}, error = "Could not create invoice") => async (dispatch) => {
  const payload = parse ? JSON.stringify(postObj) : postObj
  instance.post(`invoice?customer_id=${id}`, payload).then(() => {
    callback();
  }).catch(e => {
    toast.error(error)
    dispatch(setData(ReducersTypes.CUSTOMER_DETAILS, { showModal: false,invoiceInfo: "" }));
})
}

export const markPaidUnPaid = (ids, status, callback) => async (dispatch) => {
  instance.post("bulk/invoice",JSON.stringify({
    ids: ids,
    ...status
  })).then(() => {
    callback();
  }).catch(() => toast.error("An error occurred"))
}

export const updateInvoice = (id, postObj = {}, parse = true, callback = () => {}, error = "Could not save") => async (dispatch) => {
  const payload = parse ? JSON.stringify(postObj) : postObj
  instance.put(`invoice?id=${id}`, payload).then(() => {
    callback();
  }).catch(e => {
    toast.error(error)
    dispatch(setData(ReducersTypes.CUSTOMER_DETAILS, { showModal: false,invoiceInfo: "" }));
})
}

export const getInvoice = (id) => async (dispatch) => {
    return instance.get(`invoice?id=${id}`);
}

export const getInvoiceAttachments = (id) => async (dispatch) => {
    return instance.get(`invoice/attachment?id=${id}`);
}

export const getCustomer = (id) => async (dispatch) => {
  return instance.get(`customer?id=${id}`)
    .then(res => {
      dispatch(setData(ReducersTypes.CUSTOMER_DETAILS, { customerData: res.data.result }));
    })
}

export const removeLines = (invoiceId, ids = []) =>  async (dispatch) => {
  return instance.delete(`invoice/line/delete?id=${invoiceId}`, {
    data: JSON.stringify({
      ids: ids
    })
  });
}

export const compareDates = (date1, date2) => {
  const d1 = date1.split("/").reverse();
  const d2 = date2.split("/").reverse();
  
  if(d1[0] > d2[0])
    return true;
  else if(d1[0] === d2[0]) {
    if(d1[1] > d2[1])
      return true;
    else if(d1[1] === d2[1]) {
      if(d1[2] > d2[2])
        return true;
      else if(d1[2] === d2[2])
        return true;
    }
  }

  return false;
}

export const getChartData = (params = {}) => async (dispatch) => {
  const {
    customer_id,
    from_date,
    to_date
  } = params;

  dispatch(setData(ReducersTypes.CUSTOMER_DETAILS, { fetchingChartData: true }));

  let dates = {};
  
  const p1 = instance.get(`invoice/total?customer_id=${customer_id}&status=closed&from_date=${from_date}&to_date=${to_date}`);
  dates["Paid"] = [from_date, to_date];

  const p2 = instance.get(`invoice/total?customer_id=${customer_id}&status=open&from_date=${from_date}&to_date=${to_date}`);
  dates["Total balance"] = [from_date, to_date];

  let p3 = "";
  if(compareDates(to_date, moment().format("DD/MM/YYYY"))) {
    p3 = instance.get(`invoice/total?customer_id=${customer_id}&status=open&from_date=${from_date}&to_date=${moment().subtract(1,"day").format("DD/MM/YYYY")}`);
    dates["Overdue"] = [from_date, moment().subtract(1,"day").format("DD/MM/YYYY")];
  } else {
    p3 = instance.get(`invoice/total?customer_id=${customer_id}&status=open&from_date=${from_date}&to_date=${to_date}`);
    dates["Overdue"] = [from_date, to_date];
  }

  let p4 = "";
  if(compareDates(to_date, moment().format("DD/MM/YYYY"))) {
    if(compareDates(from_date, moment().format("DD/MM/YYYY"))) {
      p4 = instance.get(`invoice/total?customer_id=${customer_id}&status=open&from_date=${from_date}&to_date=${to_date}`);
      dates["Current"] = [from_date, to_date];
    }
    else
      p4 = instance.get(`invoice/total?customer_id=${customer_id}&status=open&from_date=${moment().format("DD/MM/YYYY")}&to_date=${to_date}`);
      dates["Current"] = [moment().format("DD/MM/YYYY"), to_date];
  }
  
  Promise.allSettled([p1,p2,p3,p4])
    .then(res => {
      let obj = {};
      res.forEach((d,idx) => {
        obj[idx] = Number(get(d,"value.data.total",0)) || 0;
      });
      dispatch(
        setData(ReducersTypes.CUSTOMER_DETAILS, {
          dates: dates,
          chartData: obj,
          fetchingChartData: false
        })
      );
    }).catch(e => {
      dispatch(
        setData(ReducersTypes.CUSTOMER_DETAILS, {
          dates: dates,
          chartData: {},
          fetchingChartData: false
        })
      );
    })
}

export const getInvoiceList = (params = {}, saveParams = true) => async (dispatch) => {
  let URL = await getQuery(params, "invoice");

  if(saveParams)
  dispatch(setData(ReducersTypes.CUSTOMER_DETAILS_FILTER, params));
  
  dispatch(setData(ReducersTypes.CUSTOMER_DETAILS, { isFetching: true }));

  instance
    .get(URL)
    .then(async (response) => {
      dispatch(
        setData(ReducersTypes.CUSTOMER_DETAILS, {
          isFetching: false,
          invoiceList: get(response, "data.result", []),
        })
      );
    })
    .catch((e) => {
      dispatch(
        setData(ReducersTypes.CUSTOMER_DETAILS, {
          isFetching: false,
        })
      );
      if (e) {
        
      }
    });
  dispatch(
    setData(ReducersTypes.CUSTOMER_DETAILS_FILTER, {
      showFilter: false,
    })
  );
};

export const resetFilter = () => (dispatch) => {
  dispatch(setData(ReducersTypes.RESET_CUSTOMER_DETAILS_FILTER, {}));
};

export const setInvoiceData = (data, reducertype) => (dispatch) => {
  dispatch(setData(reducertype, data));
};