import React from "react";
import profile from '../assets/images/profile.png'
import icon from '../assets/images/icon2.png'
import { store } from "../store";
// import { store } from "store";

function Navigation() {

    const navItems = [
        {
            name: "Customers",
            icon: <i class="fa fa-users" aria-hidden="true"></i>,
            isSelected: false
        },
        {
            name: "Import CSV",
            icon: <i class="fa fa-download" aria-hidden="true"></i>,
            isSelected: false
        },
        {
            name: "Update Profile",
            icon: <i class="fa fa-user" aria-hidden="true"></i>,
            isSelected: true
        },
        {
            name: "Client Settings",
            icon: <i class="fa fa-cog" aria-hidden="true"></i>,
            isSelected: false
        }
    ];

    return (
        <div className="navigation">
            <div className="profile">
               <div>
                 <img src={icon}/>
               </div>
               <div>
                Profile Image
               </div>
               <div>Client Name</div>
            </div>
            <div className="navItems">
                {navItems.map(item => (
                    <div className={item.isSelected ? "selected" : "not-selected"}>
                        {item.icon}
                        <span>&nbsp;&nbsp;&nbsp;{item.name}</span>
                    </div>
                ))}
            </div>
            <div className="logout" onClick={() => {
                      localStorage.clear();
                      store.dispatch({ type: 'DESTROY_SESSION' });
                      window.location.reload();
            }}>
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                <span>&nbsp;&nbsp;&nbsp;Logout</span>
            </div>
        </div>
    )
}

export default Navigation;