import moment from "moment";
import React from "react";
import ClickAwayListener from "../../components/ClickAwayListener";
import { initialCustomerDetailsFilters } from "./reducer";

function Filter(props) {
    const {
        getQueryObj,
        getInvoice,
        customerId,
        resetFilter,
        updateInvoice,
        createInvoice,
        customerDetails: {
            filter: {
                showFilter,
                from_date,
                to_date,
                status,
                invoice_type
            }
        },
        getChartData,
        setInvoiceData,
        getInvoiceList
    } = props;
    
    const onReset = () => {
        resetFilter();
        setTimeout(() => {
            getInvoiceList({
                ...getQueryObj(),
                ...initialCustomerDetailsFilters
            });
            getChartData({
                from_date: initialCustomerDetailsFilters["from_date"],
                to_date: initialCustomerDetailsFilters["to_date"],
                customer_id: window.location.pathname.split("/")[2],
                status
            });
        },0);
    }
   
    return (
        <div className="dropdown-wrap px-0">
        <div className="dropdown d-inline-block">
          <span
            className="btn dropdown-toggle btn-primary"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-testid="filter"
            onClick={() => setInvoiceData({
                showFilter: true
            },"CUSTOMER_DETAILS_FILTER")}
          >
            <i className="fa fa-sliders" aria-hidden="true"></i>&nbsp; Filter
          </span>
          <form id="filter-form">
            {showFilter ? (
              <ClickAwayListener onClickAway={() => setInvoiceData({
                showFilter: false
            },"CUSTOMER_DETAILS_FILTER")}>
                <div
                  id="outerside"
                  className={`dropdown-menu filterdroplist p-2 m-2 ${
                    showFilter && "show"
                  }`}
                  style={{width: "600px"}}
                  aria-labelledby="dropdownMenuLink"
                >
                <div className="row p-2">
                    <div className="col-md-6">
                        <strong>From Date</strong>
                        <input
                            type={"date"}
                            className="w-100 border rounded p-2"
                            value={from_date && from_date.split("/").reverse().join("-")}
                            onChange={(e) => {
                                e.target.value && setInvoiceData({
                                    from_date: e.target.value.split("-").reverse().join("/")
                                },"CUSTOMER_DETAILS_FILTER")
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <strong>To Date</strong>
                        <input
                            type={"date"}
                            className="w-100 border rounded p-2"
                            value={to_date && to_date.split("/").reverse().join("-")}
                            onChange={(e) => {
                                e.target.value && setInvoiceData({
                                    to_date: e.target.value.split("-").reverse().join("/")
                                },"CUSTOMER_DETAILS_FILTER")
                            }}
                        />
                    </div>
                    <div className="col-md-6 mt-2">
                        <div
                        className={
                            "form-group pt-1"
                        }
                        >
                        <strong>Invoice Status</strong>
                        <select
                            className="form-control"
                            value={status}
                            onChange={(e) => {
                                setInvoiceData({
                                    status: e.target.value
                                },"CUSTOMER_DETAILS_FILTER")}
                            }
                        >
                            <option value="all">
                                All
                            </option>
                            <option value="open">
                                Unpaid
                            </option>
                            <option value="closed">
                                Paid
                            </option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        <div
                        className={
                            "form-group pt-1"
                        }
                        >
                        <strong>Invoice Type</strong>
                        <select
                            className="form-control"
                            value={invoice_type}
                            onChange={(e) => {
                                setInvoiceData({
                                    invoice_type: e.target.value
                                },"CUSTOMER_DETAILS_FILTER")}
                            }
                        >
                            <option value="invoice">
                                Invoice
                            </option>
                            <option value="proforma_invoice">
                                Proforma Invoice
                            </option>
                            <option value="credit_note">
                                Credit Note
                            </option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-6 ml-auto">
                        <div className="setbtn">
                        <button
                            type="button"
                            data-testid="resetbtn"
                            className="btn resetbtn btn-light"
                            onClick={onReset}
                        >
                            Reset
                        </button>
                        <button
                            type="button"
                            data-testid="applybtn"
                            className="btn applybtn btn-primary"
                            onClick={() => {
                                getInvoiceList({
                                    ...getQueryObj(),
                                    selectedSection: "",
                                    page: 1,
                                });
                                getChartData({
                                    from_date,
                                    to_date,
                                    customer_id: window.location.pathname.split("/")[2],
                                    status
                                });
                            }}
                        >
                            Submit
                        </button>
                        </div>
                    </div>
                </div>
                </div>
              </ClickAwayListener>
            ) : null}
          </form>
        </div>
    </div>
    )
}

export default Filter;