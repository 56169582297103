import { get } from "lodash";
import React from "react";
import { Card } from "react-bootstrap";
import { convertDecimalNo } from "../../utils/index.js";

export default class FileUpload extends React.Component {
  state = {
    files: get(this.props,"preFiles",[]) || [],
  };

  generateRequestObject = (files) => {
    const { filePrefix } = this.props;
    let bodyFormData = new FormData();
    files.forEach((file) => bodyFormData.append("invoice_file", file, filePrefix ? `${filePrefix}_${file.name}`: file.name));
    return bodyFormData;
  };

  render() {
    const { files } = this.state;
    const { onUploadFiles } = this.props;
    return (
      <div className="mb-2 border position-relative fileUpload">
        <label
          className={`w-100 text-center cursor-pointer ${
            !files.length ? "position-absolute top-50" : "position-sticky mt-2"
          }`}
        >
          <span className="text-link">Click here to upload new files</span>
          <input
            key={files.length}
            type="file"
            className="d-none"
            multiple
            onChange={(e) => {
              const fileList = Object.values(e.target.files);
              this.setState({ files: [...fileList] },() => {
                onUploadFiles(this.generateRequestObject([...fileList, ...files]));
              });
            }}
          />
        </label>
        <div className="row mx-1 filelist-wrapper">
          {files.length
            ? files.map((file, i) => {
                const filetTypes = {
                  pdf: "fa fa-file-pdf-o red-file-highlighter",
                  jpeg: "fa fa-file-photo-o blue-file-highlighter",
                  png: "fa fa-file-photo-o blue-file-highlighter",
                  jpg: "fa fa-file-photo-o blue-file-highlighter",
                  xlsx: "fa fa-file-excel-o green-file-highlighter",
                  xls: "fa fa-file-excel-o green-file-highlighter",
                };
                const fileName =
                  file.name.split(".")[file.name.split(".").length - 1];
                return (
                  <Card className="col-md-2 emailthumbnail">
                    {!file.isbase64 ? (
                      <div>
                        <div className="file-wrapper">
                          <span className="invoice-file-name">{fileName}</span>
                          <span className="invoice-file-size">
                            {convertDecimalNo(file.size / 1000)}
                            kB
                          </span>
                        </div>
                        <div role="presentation" className="file-icon">
                          <i
                            className={`${
                              filetTypes[fileName]
                                ? filetTypes[fileName]
                                : "fa fa-file-o"
                            } fa-4x`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div role="presentation" cyid="file-wrapper">
                        <div className="file-wrapper">
                          <span className="invoice-file-name">{fileName}</span>
                          {!!file.content && (
                            <span className="invoice-file-size">
                              {convertDecimalNo(file.content.length / 1000)}
                              kB
                            </span>
                          )}
                        </div>
                        <div className="file-icon">
                          <i
                            className={`${
                              filetTypes[fileName]
                                ? filetTypes[fileName]
                                : "fa fa-file-o"
                            } fa-4x`}
                          />
                        </div>
                        <div className="overlay" />
                      </div>
                    )}
                    <Card.Text>
                      <div className="row py-2">
                        <div className="col-md-9 text-truncate">
                          <span title={file.name}>{file.name}</span>
                        </div>
                        <div className="col-md-3 mt-1">
                          <i
                            role="presentation"
                            title="Remove File"
                            className="fa fa-remove cursor-pointer pull-right"
                            onClick={() =>
                              this.setState({
                                files: files.filter((_, index) => index !== i),
                              })
                            }
                          />
                        </div>
                      </div>
                    </Card.Text>
                  </Card>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}
