export const ReducersTypes = {

    // Client
    SET_CLIENT_DETAILS: "SET_CLIENT_DETAILS",

    // Customer List
    RESET_CUSTOMER_LIST: "RESET_CUSTOMER_LIST",
    SET_CUSTOMER_LIST: "SET_CUSTOMER_LIST",
    SET_CUSTOMER_LIST_FILTER: "SET_CUSTOMER_LIST_FILTER",
    SET_INVOICE_LIST: "SET_INVOICE_LIST",

    // Customer Details
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
    RESET_CUSTOMER_DETAILS: "RESET_CUSTOMER_DETAILS",
    CUSTOMER_DETAILS_FILTER: "CUSTOMER_DETAILS_FILTER",
    RESET_CUSTOMER_DETAILS_FILTER: "RESET_CUSTOMER_DETAILS_FILTER",

    // Dynamic Form
    DYNAMIC_FORM: "DYNAMIC_FORM",
    ADD_DYNAMIC_FORM: "ADD_DYNAMIC_FORM"
}