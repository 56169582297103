import { formatLocaleCurrency, getCurrency } from "country-currency-map";
import keys from "lodash/keys";
import filter from "lodash/filter";
import max from "lodash/max";
import union from "lodash/union";
import get from "lodash/get";
import sum from "lodash/sum";
import pickBy from "lodash/pickBy";
import range from "lodash/range";
import {emailsFromStringRegEx, nameRegExString} from "../constants/regex";
import uniq from "lodash/uniq";
import omit from "lodash/omit";
import TokenManager from "./TokenManager";

export const nonStandardCurrencyString = {
    "European Euro": "EUR",
    "Swiss Franc": "CHF",
    "British pound": "GBP",
    "Canadian dollar": "CAD",
    "Chinese Yuan (RMB)": "CNY",
    "USA": "USD",
    "YUAN": "CNY",
    "AFN": "AFA"
  }; // update all non standard format here with correct format value
  

export const convertAmountWithDecimal = (amount = 0, currencyCode = "INR") => {
    return parseFloat(amount).toFixed(1)
  }
  //   return `${isNegative ? "-" : ""}${formatCurrency(
  //     0,
  //     currencyCode,
  //     false,
  //     true
  //   ).replace("0", "")}${parseFloat(convertAmount)
  //     .toFixed(2)
  //     .toString()
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  // };

  const formatCurrency = (
    amount,
    currency,
    shortCode = false
    ) => {
    if (!currency) {
      return;
    }
    let convertedAmount = !amount || amount === 'undefined' || amount === "0" || amount === null || typeof amount === 'string' ? 0 : amount;
    const updatedCurrency = nonStandardCurrencyString[currency] ? nonStandardCurrencyString[currency] : currency;
    const _currencySymbol = getCurrency(updatedCurrency) ? getCurrency(updatedCurrency).symbolFormat.replace("{#}", "") : updatedCurrency;
    const localCurrency = (formatLocaleCurrency(0, updatedCurrency, {
      locale: 'en-US'
    }).replace("0", ''));
    const isnegative = Number(convertedAmount) < 0;
    if (shortCode) {
      let shortAbbrUnit = "";
      const absNumb = Math.abs(convertedAmount);
      const sign = Math.sign(convertedAmount);
      if (absNumb > 999999999) {
        convertedAmount = sign * (absNumb / 1000000000);
        shortAbbrUnit = "B";
      } else if (absNumb > 999999) {
        convertedAmount = sign * (absNumb / 1000000);
        shortAbbrUnit = "M";
      } else if (absNumb > 99999) {
        convertedAmount = sign * (absNumb / 1000);
        shortAbbrUnit = "K";
      }
      let str = `${formatLocaleCurrency(convertedAmount, updatedCurrency, {
        abbreviate: shortCode,
        locale: 'en-US'
      })}${shortAbbrUnit}`;
      if(str && localCurrency.length >= 3 && localCurrency !== _currencySymbol) {
        str = str.replace(localCurrency, _currencySymbol).trim();
      }
      return `${isnegative ? "-" : ""}${str.substring(str.indexOf(_currencySymbol))}`.replace("--", "-").replace('NaN', '0');
    }
  
    let str = `${formatLocaleCurrency(amount, updatedCurrency, {
      abbreviate: shortCode,
      locale: 'en-US'
    })}`;
    if(str && localCurrency.length >= 3 && localCurrency !== _currencySymbol) {
      str = str.replace(localCurrency, _currencySymbol).trim();
    }
    return `${isnegative ? "-" : ""}${str.substring(
      str.indexOf(_currencySymbol)
    )}`
      .replace("--", "-")
      .replace("NaN", "0");
  };
  
  export default formatCurrency;

const MAX_ATTACHMENT_LIMIT = 10485760;
export function checkAttachmentSize(attachments) {
  if(!attachments || !attachments.length) {
    return true;
  }
  const totalSize = attachments.reduce((acc, cur) => {
    return acc += cur.isbase64 && get(cur, 'content', '') !== 'null' ? get(cur, 'content', '').length : get(cur, 'size', 0);
  }, 0);
  return MAX_ATTACHMENT_LIMIT > totalSize;
}

export function _get(key, path, fallback) {
  let value = get(key, path, fallback);
  return value || value == 0 ? get(key, path, fallback) : fallback;
}

export function getFormattedNumber(num = "", skiproundoff = true) {
  if (!num && num != 0) {
    return "";
  }
  let isnumfloat = num % 1 != 0;
  let result = isnumfloat ? Number(num).toFixed(1) : Math.round(num);
  if (skiproundoff) {
    result = Math.round(result);
  }
  result = Number.isNaN(result) ? 0 : result;
  return Number(result).toLocaleString("en");
}

// Formatted Amount With Currency
export function getFAWC(num = "") {
  return !num && num !== 0 ? (
    ""
  ) : (
    <span cyid="fawc">{getShortNumber(Math.round(num), true, false)}</span>
  );
}

export function kFormatter(num) {
  return Math.abs(num) > 99999
    ? getFormattedNumber(Math.abs(Math.sign(num) * (Math.abs(num) / 1000))) +
    "K"
    : getFormattedNumber(Math.abs(Math.sign(num) * Math.abs(num)));
}

export function mFormatter(num) {
  return Math.abs(num) > 999999
    ? getFormattedNumber(
    (Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(1),
    false
  ) + "M"
    : getFormattedNumber(Math.sign(num) * Math.abs(num), false);
}

export function bFormatter(num) {
  return Math.abs(num) > 999999999
    ? getFormattedNumber(
    (Math.sign(num) * (Math.abs(num) / 1000000000)).toFixed(1),
    false
  ) + "B"
    : getFormattedNumber(Math.sign(num) * Math.abs(num), false);
}

export function getShortNumber(num, appendCurrency = true, toShort = true, currencyCode = TokenManager.getCurrencyCode()) {
  let round = Number(num);
  if (Number.isNaN(round) || (!num && num !== 0) || num === null) {
    return "";
  }
  const isnegative = round < 0;
  round = Number(Math.abs(num));
  if (toShort) {
    if (round > 999999999) {
      round = bFormatter(round);
    } else if (round > 999999) {
      round = mFormatter(round);
    } else if (round > 99999) {
      round = kFormatter(round);
    } else {
      round = getFormattedNumber(Math.round(round));
    }
  } else {
    round = getFormattedNumber(round);
  }
  const result =
    round || round === 0
      ? appendCurrency
      ? isnegative
        ? `-$${round}`
        : `$${round}`
      : round
      : round;
  if (appendCurrency) {
    let currency = formatCurrency(
      Math.round(num),
      currencyCode,
      toShort,
      true
    );
    return currency.replace(/m|k|b/gi, (x) => x.toUpperCase());
  }
  return result;
}

export function getUnitCurrency(amount, currency=TokenManager.getCurrencyCode()) {
  return formatCurrency(
    amount,
    currency,
    true,
    false
  );
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp(nameRegExString(name)),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const convertAmount = (amount, currency=TokenManager.getCurrencyCode()) => {
  return formatCurrency(
    Math.round(amount),
    currency,
    false,
    true
  );
};

export const checkStringWithNumbersCommaDecimals = (str, currency) => {
  const currencyCode = currency ? currency : TokenManager.getCurrencyCode();
  const currencySymbol = convertAmount(0, currencyCode).replace("0", '');
  const string = str.replace(currencySymbol, '');
  return /^[0-9,.-]+$/.test(string);
};

export const formatCashflowCurrency = (
  amount,
  currency = TokenManager.getCurrencyCode()
) => {
  const _currencySymbol = convertAmount(0, currency).replace("0", '');
  const isnegative = Number(amount) < 0;
  let str = `${formatLocaleCurrency(amount, currency, {
    abbreviate: false,
    locale: 'en-US'
  })}`;
  return `${isnegative ? "-" : ""}${str.substring(
    str.indexOf(_currencySymbol)
  )}`
    .replace("NaN", "")
    .replace(_currencySymbol, "");
};

export const convertNumberWithDecimal = (number) => {
  return parseFloat(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertDecimalNo = (num) => {
  if (!num) {
    return 0;
  }
  return !Number.isInteger(Number(num))
    ? Number(Number(num).toFixed(2))
    : Number(num);
};

export const getRoundedIntegerNo = (num) => {
  const _num = convertDecimalNo(num);
  return _num ? Math.round(_num) : 0;
};

export const isInvoiceClosed = (data) => {
  if (get(data, "date_closed", "")) {
    return true;
  } else {
    return false;
  }
};

export const isInvoiceOpen = (data) => {
  if (get(data, "total_amount_due", 0) > 0) {
    return true;
  } else {
    return false;
  }
};

export const paginationNextButtonStatus = (
  prev = 0,
  next = 0,
  rowCount,
  limit = 20
) => {
  // if there is no pagination flag provided then will use response array length count for that page.
  if (!next) {
    return rowCount <= limit;
  }
  return false;
};

export const percentageAmountGrowth = (num = 0, per = 1) =>
  convertDecimalNo((num / 100) * per);

export const percentageGrowthRate = (prevAmount, currAmount) => {
  const per = convertDecimalNo(((currAmount - prevAmount) / prevAmount) * 100);
  return isFinite(per) ? per : 0;
};

export const formatNumber = (num, currency=TokenManager.getCurrencyCode()) => {
  // if (num < 0) {
  //   const res = Math.abs(Math.round(num))
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //   return res && res != "NaN" ? `-$${res}` : "$0";
  // } else {
  //   const res = Math.abs(Math.round(num))
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //   return res && res != "NaN" ? `$${res}` : "$0";
  // }
  return formatCurrency(
    Math.round(num || 0),
    currency,
    false,
    true
  );
};

export const fibonacci = (n) => {
  if (!n || n <= 1) {
    return [0, 1];
  } else {
    var s = fibonacci(n - 1);
    s.push(s[s.length - 1] + s[s.length - 2]);
    return s;
  }
};

export const fibonacciSum = (n) => {
  return sum(fibonacci(n));
};

export const rangeSum = (n) => {
  return sum(range(n));
};

export const nearbyNumber = (n, percentageDifference = 0.1) => {
  const res = n + n * percentageDifference;
  return <span className="text-primary">{formatNumber(res)}</span>;
};

export const isValidString = (n) => n && n !== "null";

export const isValidNumber = (n) => n || n == 0 || n == 0.0;

export const processStringCell = (str) =>
  str && typeof str === "string" ? str.replace(/[^a-zA-Z0-9 ]/g, "") : "";

export const processDefaultValueNumber = (value) => {
  if (value !== 0 && value !== "" && value !== null && Number(value)) {
    return value;
  }
  return undefined;
};

export const isPositiveInteger = (str) => {
  var n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
};

export const processInputChangedValue = (str, currency) => {
  if (str === "" || str === undefined) {
    return "";
  } else {
    const currencyCode = currency ? currency : TokenManager.getCurrencyCode();
    const currencySymbol = convertAmount(0, currencyCode).replace("0", '');
    const string = String(str).replace(currencySymbol, '');
    return string ? Number(string) : 0;
  }
};

export const changedKeys = (o1 = {}, o2 = {}) => {
  const _keys = union(keys(o1), keys(o2));
  return filter(_keys, function (key) {
    return o2[key] !== undefined && get(o1, key, "") !== get(o2, key, "");
  });
};

export const objectWithChangedKeys = (original = {}, changed = {}) => {
  const _changedKeys = changedKeys(original, changed);
  let res = {};
  _changedKeys.forEach((key) => {
    res[key] = changed[key];
  });
  return res;
};

export const getCustomFields = async (customFields = [],type) => {
  const obj = type === "keys" || "values" ? [] : {};

  customFields.length && customFields.forEach(field => {
    if(type === "keys")
    obj.push(field.erp_field_name);
    else if(type === "object")
    obj[field.erp_field_name] = field.id;
    else
    obj.push(field.id);
  });

  return obj;
}

export const getEnabledKeysValue = async (customFields, visibleColumns) => {
  const values = [];
  const keys = await getCustomFields(customFields,"keys")
  keys.forEach(async (key,idx) => {
    if(visibleColumns[key]) {
      const value = await getCustomFields(customFields,"values");
      values.push(value[idx]);
    }
  });
  return values;
}

export function getIntegerMode(numbers = []) {
  // as result can be bimodal or multi-modal,
  // the returned result is provided as an array
  // mode of [3, 5, 4, 4, 1, 1, 2, 3] = [1, 3, 4]
  var modes = [],
    count = [],
    i,
    number,
    maxIndex = 0;

  for (i = 0; i < numbers.length; i += 1) {
    number = numbers[i];
    count[number] = (count[number] || 0) + 1;
    if (count[number] > maxIndex) {
      maxIndex = count[number];
    }
  }

  for (i in count)
    if (count.hasOwnProperty(i)) {
      if (count[i] === maxIndex) {
        modes.push(Number(i));
      }
    }
  return max(modes);
}

export const isnearbynumber = (base, num, diff = 1) =>
  num > base + diff || num < base - diff;

export const getUnionTags = (rows) => {
  const rowLength = rows.length;
  const result = rows.map((d) => d.tags.map((e) => e.id)).flat();
  let counts = {};
  for (let i = 0; i < result.length; i++) {
    counts[result[i]] = 1 + (counts[result[i]] || 0);
  }
  let inAllRows = [],
    inSomeRows = [];
  Object.entries(counts).forEach(([key, val]) => {
    if (val === rowLength) inAllRows.push(parseInt(key));
    else inSomeRows.push(parseInt(key));
  });
  return {result: uniq(result), inAllRows, inSomeRows};
};

export const onNumberInputKeyPressed = (e, callback, category = "") => {
  if (e.charCode == 45) {
    if (e.target.value.includes("-")) {
      e.preventDefault();
    } else {
      e.target.value = !e.target.value
        ? "-"
        : e.target.value.replaceAll(",", "") * -1;
      e.target.value && e.preventDefault();
      callback && callback(e, category);
    }
  }
};

export const dataURItoBlob = (dataURI) => {
  var array, binary, i;
  binary = atob(dataURI.split(",")[1]);
  var mimeType = dataURI.match(/(:)([a-z\/]+)(;)/)[2];
  array = [];
  i = 0;
  while (i < binary.length) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  return new Blob([new Uint8Array(array)], {
    type: mimeType,
  });
};

export const extractEmails = (text) => {
  if (text === "" || text === null)
    return "";
  else
    return (text.match(emailsFromStringRegEx) || "");
};

export const extractRemainingText = (text) => {
  text = text
    .replaceAll(emailsFromStringRegEx, "")
    .replaceAll(";", "")
    .replaceAll(",", "")
    .replaceAll(/\s/g, "");
  return text;
};

export function setReducerData(type, data) {
  if (!type) return;
  return {
    type,
    payload: {
      ...data,
    },
  };
}

// Function to round off Notification count
export function intToString(num) {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
      return num;
  }
  let si = [
    {v: 1E3, s: "K"},
    {v: 1E6, s: "M"},
    {v: 1E9, s: "B"},
    {v: 1E12, s: "T"},
    {v: 1E15, s: "P"},
    {v: 1E18, s: "E"}
    ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
          break;
      }
  }
  return (num / si[index].v).toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}

export function updateObjectWithAnotherObject(targetObject, obj) {
  Object.keys(obj).forEach(function (key) {
    if (undefined === obj[key] || null === obj[key]) {
      delete targetObject[key];
    } else if ("object" === typeof obj[key] && !Array.isArray(obj[key])) {
      if (
        !(
          "object" === typeof targetObject[key] &&
          !Array.isArray(targetObject[key])
        )
      ) {
        targetObject[key] = {};
      }
      updateObjectWithAnotherObject(targetObject[key], obj[key]);
    } else {
      targetObject[key] = obj[key];
    }
  });
}

export const sumArray = (array) => {
  if (!array || !array.length) return
  return array.reduce((r, a) => {
    a.forEach((b, i) => {
      r[i] = (r[i] || 0) + b;
    });
    return r;
  }, []);
};

// remove empty keys from object
export const removeEmptyValueFromObject = (obj) => {
  if (!obj || Array.isArray(obj)) return obj;
  return pickBy(obj, (value) => !!value && value !== 'null' && value !== 'undefined' && value !== ' ');
};

// check for foreign currency of invoice vs client vs entity and return flags for operations;
export const checkForeignCurrency = (invoiceCurency) => {
  const clientCurrency = TokenManager.getClientCurrencyCode();
  const isEntitySelected = TokenManager.isCorpEntityRelatedToUser();
  const corpEntityCurrency = isEntitySelected ? TokenManager.getCurrencyCode() : '';
  const isDifferentCurrency =
  isEntitySelected && corpEntityCurrency && clientCurrency !== corpEntityCurrency;
  const showForeignAmount = !isEntitySelected && invoiceCurency && invoiceCurency !== TokenManager.getCurrencyCode(); 
  const keyNamePrefix = isDifferentCurrency ? "foreign_" : "";

  return {
    showForeignAmount,
    keyNamePrefix,
    isDifferentCurrency
  };
}

// get ux key values form localstorage
export const getUXSettingsFromStorage = (uxKey) => {
  if(!uxKey) { return {}};
  const uxStorage = localStorage.getItem("persist:payference");
  const parsedUxStorage = uxStorage ? JSON.parse(get(JSON.parse(uxStorage), 'ux', null)) : "";
  const uxKeyData = parsedUxStorage ? get(parsedUxStorage, uxKey, 'null') : {}
  return uxKeyData;
};

export const compareState = (initialState = {},currentState = {}) => {
  if((!initialState && currentState) || (initialState && !currentState))
  return [];
  
  let obj = [];
  for(const [key,value] of Object.entries(currentState || {})) {
    if((currentState[key] !== initialState[key])) {
      obj.push({
        [key]: value
      })
    }
  }

  return obj;
}

export const savePopUpState = (condition, flag, key, callback = () => {}) => {
  let showUnsavedPopUp = JSON.parse(localStorage.getItem("showUnsavedPopUp")) || [];
  const idx = showUnsavedPopUp.length ? showUnsavedPopUp.indexOf(key) : -1;

  if(flag) {
    showUnsavedPopUp = showUnsavedPopUp.filter(d => d !== key)
  }
  else {
    showUnsavedPopUp = condition
                      ? (idx > -1 ? showUnsavedPopUp : [...showUnsavedPopUp,key])
                      : (idx > -1 ? showUnsavedPopUp.filter(d => d !== key) : showUnsavedPopUp)
  }
  
  localStorage.setItem("showUnsavedPopUp", JSON.stringify(showUnsavedPopUp));
  callback(showUnsavedPopUp);
}

export const checkEmptyFields = (obj) => {
  return Object.keys(obj).find(field => {
    if(Array.isArray(obj[field])) {
      return obj[field].length;
    }
    if(typeof obj[field] === 'object' && obj[field] !== null) {
      return Object.keys(obj[field]).length;
    }
    else {
      return obj[field];
    }
  })
}

export const getApplicableCustomFields = (flag = true,customFieldString = {}, type = "customer") => {
  if(type === "customer") {
    let payloadObj = {};
    let URL = "";
      if (Object.keys(customFieldString).length > 0) {
        let newObj = {};
        for (const [key, value] of Object.entries(customFieldString)) {
          newObj[key] = {};
          for (const [key1, value1] of Object.entries(value)) {
            if (value1?.length > 0 || typeof value1 === "number") {
              newObj[key] = { ...newObj[key], [key1]: value1 };
            }
          }
          if (Object.keys(newObj[key]).length <= 2) newObj = omit(newObj, key);
        }
        let customFieldIds = [];
        let masterId;
        for (var obj in newObj) {
          masterId = newObj[obj].custom_field_master_id;
          customFieldIds.push(masterId);
          switch (newObj[obj].type) {
            case "string":
              flag
                ? (payloadObj[`erp_field_string_value_${masterId}`] =
                    newObj[obj].erp_field_string_value)
                : (URL =
                    URL +
                    `&erp_field_string_value_${masterId}=${newObj[obj].erp_field_string_value}`);
              break;
            case "float":
              const less = newObj[obj].hasOwnProperty(
                "erp_field_float_value_less_than"
              );
              const greater = newObj[obj].hasOwnProperty(
                "erp_field_float_value_greater_than"
              );
              if (flag) {
                if (less)
                  payloadObj[`erp_field_float_value_${masterId}_less_than`] =
                    Number(newObj[obj].erp_field_float_value_less_than);
                if (greater)
                  payloadObj[`erp_field_float_value_${masterId}_greater_than`] =
                    Number(newObj[obj].erp_field_float_value_greater_than);
              } else
                URL =
                  URL +
                  `${
                    less
                      ? `&erp_field_float_value_${masterId}_less_than=${newObj[obj].erp_field_float_value_less_than}`
                      : ""
                  }${
                    greater
                      ? `&erp_field_float_value_${newObj[obj].custom_field_master_id}_greater_than=${newObj[obj].erp_field_float_value_greater_than}`
                      : ""
                  }`;
              break;
            case "date":
              if (flag) {
                payloadObj[`erp_field_date_value_${masterId}_from_date`] =
                  newObj[obj].erp_field_date_value_from_date;
                payloadObj[`erp_field_date_value_${masterId}_to_date`] =
                  newObj[obj].erp_field_date_value_to_date;
              } else
                URL =
                  URL +
                  `&erp_field_date_value_${masterId}_from_date=${newObj[obj].erp_field_date_value_from_date}&erp_field_date_value_${newObj[obj].custom_field_master_id}_to_date=${newObj[obj].erp_field_date_value_to_date}`;
              break;
            default:
              break;
          }
        }
        if (customFieldIds.length > 0 && !flag) {
          URL = URL + `&custom_field_master_id=${customFieldIds}`;
          return URL;
        }
        if (flag) {
          payloadObj[`custom_field_master_id`] = customFieldIds.toString();
          return payloadObj;
        }
      }
  }
  else {
    if (customFieldString && Object.keys(customFieldString).length) {
      let customFilterKeyVals = {};
      let masterId = [];
      customFilterKeyVals["custom_field_master_id"] =
        Object.keys(customFieldString).join();
      Object.keys(customFieldString).forEach((d) => {
        const filter = customFieldString[d];
        let flag = false;
        Object.keys(filter).forEach((f) => {
          if (f !== "custom_field_master_id" && filter[f]) {
            customFilterKeyVals[f] = filter[f];
            flag = true;
          }
        });
        if (flag) {
          masterId.push(d);
          flag = false;
        }
      });
      if (masterId.length) {
        return {
          ...customFilterKeyVals,
          custom_field_master_id: masterId.join(),
        }
      }

      return {};
    }
  }
}

export const replaceQuotes = (value) => {
  if([null,"",undefined].includes(value))
  return "-";

  return value.replace(/['"]/g, '');
}
  