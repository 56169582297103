import React, { useState, useEffect, useLayoutEffect } from "react";

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  
//   export function getShortNumber(num, appendCurrency = true, toShort = true, currencyCode = "INR") {
//     let round = Number(num);
//     if (Number.isNaN(round) || (!num && num !== 0) || num === null) {
//       return "";
//     }
//     const isnegative = round < 0;
//     round = Number(Math.abs(num));
//     if (toShort) {
//       if (round > 999999999) {
//         round = bFormatter(round);
//       } else if (round > 999999) {
//         round = mFormatter(round);
//       } else if (round > 99999) {
//         round = kFormatter(round);
//       } else {
//         round = getFormattedNumber(Math.round(round));
//       }
//     } else {
//       round = getFormattedNumber(round);
//     }
//     const result =
//       round || round === 0
//         ? appendCurrency
//         ? isnegative
//           ? `-$${round}`
//           : `$${round}`
//         : round
//         : round;
//     if (appendCurrency) {
//       let currency = formatCurrency(
//         Math.round(num),
//         currencyCode,
//         toShort,
//         true
//       );
//       return currency.replace(/m|k|b/gi, (x) => x.toUpperCase());
//     }
//     return result;
//   }