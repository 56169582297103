import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {store, persistedStore} from './store.js';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import Root from './routes/index'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/global.css";
// import "./assets/css/customers.css"
// import "./assets/css/datepicker.css"
// import "./assets/css/perfect-scrollbar.css"
// import "./assets/css/responsive.css"
import 'font-awesome/css/font-awesome.min.css';

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <Root/>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
