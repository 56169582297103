import axios from 'axios'
import TokenManager from './utils/TokenManager';
import moment from 'moment';
import { get } from 'lodash';

export let cancelAPI = axios.CancelToken.source();

export const setAuthorizationToken = () => {
    return TokenManager.isLoggedIn();
};

const ConvertHeadersForBackend = (headers) => {
    headers.Authorization = setAuthorizationToken();
    headers["todays_date"] = moment().format("MM/DD/YYYY");
    headers["Content-Type"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    return headers;
};

const urlCheck = (url) => {
    if (url.includes("?")) {
      const prev = url.substr(0, url.indexOf("?") + 1);
      const url_to_transform = url.substr(url.indexOf("?") + 1, url.length);
      const aft = transformURL(url_to_transform);
      return prev + aft;
    }
    return url;
};

const transformURL = (url) =>
  url
    .split("&")
    .filter((x) => x)
    .map((x) => x.split("="))
    .map((param) =>
      ["from_date", "to_date"].includes(param[0]) ? [param[0], param[1]] : param
    )
    .filter((param) => param)
    .map((x) => x.join("="))
    .join("&");
  

const instance = axios.create({
    baseURL: "https://devbe-finista.onrender.com/api/",
    transformRequest: [
        (data, headers) => {
          headers = ConvertHeadersForBackend(headers);
          return data;
        },
    ],
});

// declare a request interceptor
instance.interceptors.request.use(
    (config) => {
      const { url } = config;
      config.url = urlCheck(url);
      if (!get(config, "auth", "")) {
        config.auth = {
          username: TokenManager.isLoggedIn(),
          password: Math.random().toString(20),
        };
      }
      config.cancelToken = cancelAPI.token;
      return config;
    },
    (error) => {
      // handle the error
      console.log("___error in axios req", JSON.stringify(error));
      return Promise.reject(error);
    }
  );
  
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        console.log("___error in axios resp", JSON.stringify(error.response));
        return Promise.reject({
          ...error.response.data,
          status: error.response.status,
        });
      }
      console.log("___error in axios resp", JSON.stringify(error));
      return Promise.reject(error);
    }
  );
  

export default instance;