import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import get from 'lodash/get'
import uniqBy from "lodash/uniqBy";
import PieChartPlaceholder from "../../components/Piechart.js";
import { Cell, Label, Pie, PieChart, Sector } from "recharts";
import { useWindowSize } from "../../handlers";
import { createInvoice, exportData, getChartData, getInvoice, getInvoiceAttachments, getInvoiceList, resetFilter, setInvoiceData, updateInvoice, getCustomer, markPaidUnPaid, removeLines } from "./actions.js";
import { connect } from "react-redux";
import CreateInvoice from "./createInvoice.js";
import Export from "./export.js";
import RenderTemplate from "../../templates/invoiceTemplate.js";
import { Button } from "react-bootstrap";
import Filter from "./filter.js";
import { ShimmerCircularImage, ShimmerTable, ShimmerText } from "react-shimmer-effects";
import { getDate } from "../../utils/dates.js";
import { convertAmount, getFAWC, getShortNumber } from "../../utils/amount.js";
import toast from "react-hot-toast";
import TokenManager from "../../utils/TokenManager.js";

const DonutChart = ({ data, onRecordClick, total, selectedStatusObject }) => {
  const [activeindex, setactiveindex] = useState(null);
  const [width] = useWindowSize();
  const isMobile = width <= 768;
  const divider = isMobile ? 1.5 : 1;

  useEffect(() => {
    (get(selectedStatusObject, "dataindex", null) ||
      get(selectedStatusObject, "dataindex", null) === 0) &&
      setactiveindex(get(selectedStatusObject, "dataindex", null));
  }, [selectedStatusObject]);

  function onPieEnter(_, index) {
    setactiveindex(index);
  }
  function onPieLeave() {
    setactiveindex(null || get(selectedStatusObject, "dataindex", null));
  }

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;
  
    return (
      <g>
        <Sector
          className="cursor-pointer"
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <PieChart
      width={320 / divider}
      height={320 / divider}
      style={{ margin: isMobile ? "0 auto" : "" }}
    >
      <Pie
        animationDuration={1000}
        activeIndex={activeindex}
        activeShape={renderActiveShape}
        data={data}
        innerRadius={60 / divider}
        outerRadius={140 / divider}
        fill="#8884d8"
        dataKey="value"
        onClick={(e) => {
          onRecordClick(e.payload.payload);
        }}
        onMouseEnter={onPieEnter}
        onMouseLeave={onPieLeave}
      >
        <Label
          className="cursor-pointer"
          width={30}
          position="center"
          onClick={() => onRecordClick(null)}
        >
          {`Total ${getShortNumber(total)}`}
        </Label>
        {data.map((_, index) => (
          <Cell
            className="cursor-pointer"
            fill={COLORS[index]}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

const COLORS = [
  "#7CA82D",
  "#EF853A",
  "#2B7EF6",
  "#DB504D",
  "#424348",
  "#90ED7D",
  "#3C861F",
  "#F9D7E0",
];

const rows = [
  { id: 1 ,name: "Raj Kale", address: "Junnar, Pune", contact: "8888228947", gst_no: "HJehfje672G" },
  { id: 2 ,name: "Shubham Kale", address: "Japan, Pune", contact: "9423533919", gst_no: "HJehfje672G" },
  { id: 3 ,name: "John Kale", address: "Mumbai, Pune", contact: "8888228947", gst_no: "HJehfje672G" },
  { id: 4 ,name: "Raj Kale", address: "Junnar, Pune", contact: "8888228947", gst_no: "HJehfje672G" },
  { id: 5 ,name: "Shubham Kale", address: "Japan, Pune", contact: "9423533919", gst_no: "HJehfje672G" },
  { id: 6 ,name: "John Kale", address: "Mumbai, Pune", contact: "8888228947", gst_no: "HJehfje672G" },
  { id: 7 ,name: "Raj Kale", address: "Junnar, Pune", contact: "8888228947", gst_no: "HJehfje672G" },
  { id: 8 ,name: "Shubham Kale", address: "Japan, Pune", contact: "9423533919", gst_no: "HJehfje672G" },
  { id: 9 ,name: "John Kale", address: "Mumbai, Pune", contact: "8888228947", gst_no: "HJehfje672G" }
];

function CustomerDetails(props) {

  const {
    customerDetails,
    dynamicformReducer,
    customerDetails: {
      filter: {
        page,
        sort_by,
        sort_order,
        from_date,
        to_date,
        status,
        invoice_type
      },
      dates,
      showModal,
      chartData,
      invoiceList,
      isFetching,
      customerData,
      selectedSection,
      fetchingChartData,
      resetCustomerFilter
    },
    getInvoice,
    exportData,
    removeLines,
    resetFilter,
    getCustomer,
    getChartData,
    createInvoice,
    updateInvoice,
    getInvoiceList,
    setInvoiceData,
    markPaidUnPaid,
    getInvoiceAttachments,
  } = props;

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectedStatusObject, setSelectedStatusObject] = useState(null);
  const isrowselected = !!selectedInvoices.length;
  const isheaderchecked = selectedInvoices.length
      ? invoiceList.every((x) => selectedInvoices.some((y) => y.id === x.id))
      : false;

  const needExpander = invoiceList.some((d) => get(d, "invoice_lines", []).length);
  const [downloading, setDownloading] = useState(false);
 
    const _columns = [
      {
        Expander: (props) =>
          get(props, "original.invoice_lines", []).length ? (
            <i className={`fa ${props.isExpanded ? "fa-caret-down" : "fa-caret-right"} rt-expander expandable`} aria-hidden="true"></i>
          ) : null,
        className: "expand-row",
        expander: needExpander,
        show: needExpander,
      },
      {
        Header: (
          <input
            className="md-checkbox"
            style={{marginLeft: "15px", marginTop: '5px'}}
            key={isheaderchecked}
            onClick={(e) => {
              let data = [];
              if (!e.target.checked && selectedInvoices.length) {
                data = selectedInvoices.filter((x) =>
                  invoiceList.every((y) => y.id !== x.id)
                );
              } else {
                data = [...selectedInvoices, ...invoiceList];
              }
              setSelectedInvoices(uniqBy(data, "id"));
            }}
            type="checkbox"
            checked={isheaderchecked}
          />
        ),
        accessor: "isselected",
        Cell: (props) => {
          const isHeader = !get(props,"original.invoice_lines",[]).length ? get(props,"original.invoice_number", false) : true;
          if(isHeader)
          return (
            <div>
              <input
                onClick={(e) => {
                  e.stopPropagation();
                  const data = e.target.checked
                    ? selectedInvoices.concat([props.original])
                    : selectedInvoices.filter(
                        (d) => d.id !== props.original.id
                      );
                    setSelectedInvoices(uniqBy(data, "id"));
                }}
                key={selectedInvoices.some((d) => d.id === props.original.id)}
                className={`md-checkbox`}
                style={{marginLeft: "10px"}}
                // data-cy={`md-checkbox-${props.original.invoice_number}`}
                type="checkbox"
                checked={selectedInvoices.some(
                  (d) => d.id === props.original.id
                )}
              />
            </div>
          );
        },
        sortable: false,
        filterable: false,
        minWidth: 50,
      },
        {
            Header: () => (
              <span className="cursor-pointer d-block text-left ml-3" 
                onClick={() => setAPISort("id")}
                >
                Invoice Number
                <span className="sort-icons">
                  <i className="fas fa fa-caret-up"></i>
                  <i className="fas fa fa-caret-down"></i>
                </span>
              </span>
            ),
            accessor: "invoice_number",
            minWidth: 200,
            className: "text-left",
            id: "invoice_number",
            sortable: true,
            Cell: (row) => {
              return <span className="pl-3 text-link" onClick={(e) => {
                e.stopPropagation();
                setInvoiceData({
                  isFetching: true
                },"CUSTOMER_DETAILS")
                  getInvoice(row.original.id)
                  .then(res => {
                      setInvoiceData({
                          invoiceInfo: res.data.result,
                          showModal: 'update_invoice',
                          isFetching: false
                      },"CUSTOMER_DETAILS")
                  });
              }}>{get(row,"value","-")}</span>;
            },
        },
        {
          Header: () => (
            <span className="cursor-pointer d-block text-left ml-3" 
              onClick={() => setAPISort("invoice_type")}
              >
              Invoice Type
              <span className="sort-icons">
                <i className="fas fa fa-caret-up"></i>
                <i className="fas fa fa-caret-down"></i>
              </span>
            </span>
          ),
          accessor: "invoice_type",
          minWidth: 200,
          className: "text-left",
          id: "invoice_type",
          sortable: true,
          Cell: (row) => {
            return <span className="pl-3">{get(row,"value","-")}</span>;
          },
      },
        {
          Header: () => (
            <span className="cursor-pointer d-block text-left" 
              onClick={() => setAPISort("id")}
              >
              Invoice Attachment
            </span>
          ),
          minWidth: 200,
          className: "text-left",
          sortable: false,
          Cell: (row) => {
            const isHeader = !get(row,"original.invoice_lines",[]).length ? get(row,"original.invoice_number", false) : true;
            if(isHeader && get(row,"original.attachment_filename",""))
            return (
              <Button 
                variant="primary"
                onClick={() => {
                    setDownloading(row.original.id);
                    
                    setTimeout(() => {
                      setDownloading(false);
                      window.open(`https://devbe-finista.onrender.com/api/invoice/attachment?id=${row.original.id}&data=${TokenManager.isLoggedIn()}`)
                    },1000)
                }}
            >
                {downloading === row.original.id ? <i class="fa fa-spinner fa-spin"></i> : <i class="fa fa-download"></i>}&nbsp;&nbsp;Download
            </Button>
            )
          },
      },
        {
            Header: () => (
              <span className="cursor-pointer d-block text-left ml-3" 
                onClick={() => setAPISort("total_amount_due")}
                >
                Total Amount Due
                <span className="sort-icons">
                  <i className="fas fa fa-caret-up"></i>
                  <i className="fas fa fa-caret-down"></i>
                </span>
              </span>
            ),
            accessor: "total_amount_due",
            minWidth: 200,
            className: "text-left",
            id: "total_amount_due",
            sortable: true,
            Cell: (row) => {
              return <span className="pl-3">{convertAmount(get(row,"value","-"))}</span>;
            },
        },
        {
          Header: () => (
            <span className="cursor-pointer d-block text-left ml-3" 
              onClick={() => setAPISort("due_date")}
              >
              Due Date
              <span className="sort-icons">
                <i className="fas fa fa-caret-up"></i>
                <i className="fas fa fa-caret-down"></i>
              </span>
            </span>
          ),
          accessor: "due_date",
          minWidth: 200,
          className: "text-left",
          id: "due_date",
          sortable: true,
          Cell: (row) => {
            return <span className="pl-3">{getDate(get(row,"value","")) || "-"}</span>;
          },
        },
        {
          Header: () => (
            <span className="cursor-pointer d-block text-left ml-3" 
              onClick={() => setAPISort("date_closed")}
              >
              Closed Date
              <span className="sort-icons">
                <i className="fas fa fa-caret-up"></i>
                <i className="fas fa fa-caret-down"></i>
              </span>
            </span>
          ),
          accessor: "date_closed",
          minWidth: 200,
          className: "text-left",
          id: "date_closed",
          sortable: true,
          Cell: (row) => {
            return <span className="pl-3">{getDate(get(row,"value","")) || "-"}</span>;
          },
        },
        {
            Header: () => (
              <span className="cursor-pointer d-block text-left ml-3" 
                onClick={() => setAPISort("invoice_amount")}
                >
                Invoice Amount
                <span className="sort-icons">
                  <i className="fas fa fa-caret-up"></i>
                  <i className="fas fa fa-caret-down"></i>
                </span>
              </span>
            ),
            accessor: "invoice_amount",
            minWidth: 200,
            className: "text-left",
            id: "invoice_amount",
            sortable: true,
            Cell: (row) => {
              const isLineItem = !get(row,"original.invoice_lines",[]).length ? !get(row,"original.invoice_number", false) : false;
              return <span className="pl-3">{isLineItem ? convertAmount(get(row,"original.amount","-")) : convertAmount(get(row,"value","-"))}</span>;
            },
        },
        {
          Header: () => (
            <span className="cursor-pointer d-block text-left ml-3" 
              onClick={() => setAPISort("invoice_date")}
              >
              Invoice Date
              <span className="sort-icons">
                <i className="fas fa fa-caret-up"></i>
                <i className="fas fa fa-caret-down"></i>
              </span>
            </span>
          ),
          accessor: "invoice_date",
          minWidth: 200,
          className: "text-left",
          id: "invoice_date",
          sortable: true,
          Cell: (row) => {
            return <span className="pl-3">{getDate(get(row,"value","")) || "-"}</span>;
          },
        },
    ];
    const setAPISort = (sort_by_key) => {
      setInvoiceData({
        page: 1,
        sort_by: sort_by_key,
        sort_order:
          sort_by_key !== sort_by || sort_order === "asc" ? "desc" : "asc",
      },"CUSTOMER_DETAILS_FILTER");
    };

    const getQueryObj = () => {
      return {
        page,
        customer_id: window.location.pathname.split("/")[2],
        include_lines: 1,
        per_page: 20,
        sort_by: sort_by,
        sort_order: sort_order,
        from_date,
        to_date,
        status,
        invoice_type
      }
    }

    const getExportQueryObj = () => {
      return {
        customer_id: window.location.pathname.split("/")[2],
        include_lines: 1,
        sort_by: sort_by,
        sort_order: sort_order,
        is_export: 1,
        from_date,
        to_date,
        status,
        invoice_type
      }
    }

    useEffect(() => {
      getInvoiceList(getQueryObj());
      getCustomer(window.location.pathname.split("/")[2]);
    },[sort_by, sort_order, page, resetCustomerFilter]);

    useEffect(() => {
      getChartData({
        status,
        from_date,
        to_date,
        customer_id: window.location.pathname.split("/")[2],
      });
    },[]);

    const donutChartData = [
      {
        name: "Paid",
        value: chartData[0] || 0,
        dataindex: 0,
        status: "closed",
      }, //paid
      {
        name: "Current", // Balance
        value: chartData[3] || 0,
        dataindex: 1,
        status: "open"
      }, //balance
      {
        name: "Overdue",
        value: chartData[2] || 0,
        dataindex: 2,
        status: "open"
      }, //overdue
      {
        name: "Total balance", // Balance
        value: chartData[1] || 0,
        status: "open",
        dataindex: [1, 2],
      }, //total_balance
    ];

    const total = donutChartData.filter(section => section.name !== "Total balance").map(section => section.value).reduce((total, num) => total+num)

    const setNextPage = () => {
      setInvoiceData({
        page: page + 1,
      }, "CUSTOMER_DETAILS_FILTER")
    };
  
    const setPrevPage = () => {
      setInvoiceData({
        page: page - 1,
      }, "CUSTOMER_DETAILS_FILTER")
    };

    const tableProps = isrowselected
      ? {
          TheadComponent: () => <div></div>,
        }
    : {};
      
    return (
        <div className="invoiceList">
          <div className="invoiceTitle" onClick={() => {
            props.history.push("/");
          }}>
            <span
              data-cy="Back"
              title="Back"
            >
              <i
                className="fa fa-arrow-left"
                style={{
                  marginRight: "15px",
                  ariaHidden: true,
                  cursor: "pointer",
                }}
              />
            </span>
            &nbsp;{get(customerData,"name","")}
          </div>
            <div className="charts">
            {!fetchingChartData ? (
              total ? (
                <div className="mb-4">
                  <div>
                    <DonutChart
                      onRecordClick={(record) => {
                        setInvoiceData({
                          selectedSection: record,
                        },"CUSTOMER_DETAILS");
                        setInvoiceData({
                          status: record.status,
                        },"CUSTOMER_DETAILS_FILTER");
                        getInvoiceList({
                          ...getQueryObj(),
                          from_date: dates[record.name][0],
                          to_date: dates[record.name][1],
                          status: record.status,
                        }, false);
                      }}
                      data={donutChartData.filter(
                        (x) => x.name != "Total balance"
                      )}
                      total={total}
                      selectedSection={selectedSection}
                    />
                  </div>
                  <div
                    className="hide-scroll"
                    style={{
                      margin: "",
                      marginLeft: 4,
                      overflowY: "auto",
                    }}
                  >
                    <div className="mt-4">
                      {donutChartData &&
                        donutChartData.map((entity, i) =>
                          get(entity, "value", "") ? (
                            <div
                              className="col-md-12 p-2 cursor-pointer show-ellipses"
                              onClick={() => {
                                setInvoiceData({
                                  selectedSection: entity,
                                },"CUSTOMER_DETAILS");
                                setInvoiceData({
                                  status: entity.status,
                                },"CUSTOMER_DETAILS_FILTER");
                                getInvoiceList({
                                  ...getQueryObj(),
                                  from_date: dates[entity.name][0],
                                  to_date: dates[entity.name][1],
                                  status: entity.status,
                                }, false);
                              }}
                            >
                                <span
                                  className={`ml-2 mr-2 donutchart-legend-dot`}
                                  style={{
                                    backgroundColor: `${get(
                                      entity,
                                      "line-color",
                                      COLORS[i % 8]
                                    )}`,
                                  }}
                                />
                              {get(entity, "name", "-")}&nbsp;(
                              <span
                                cyid={`customer-details-donut-chart-total-${entity.dataindex}`}
                              >
                                {getFAWC(entity.value)}
                                {/* {get(entity, "name", "-")}&nbsp;({Math.round((entity.value / total) * 100)}%) */}
                              </span>
                              )
                            </div>
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <PieChartPlaceholder
                  message={
                    <div>
                      <p className="text-center">
                        No data available in the selected date range. <br />
                        Try selecting a different date range from the date filter
                      </p>
                    </div>
                  }
                />
              )
            ) : (
              <span className="d-flex justify-content-center align-items-center mb-4 mt-n2" style={{width: "50%", marginLeft: "25%"}}>
                <span><ShimmerCircularImage size={150} /></span>
                <span className="w-50 m-4"><ShimmerText line={5} gap={10} /></span>
              </span>
            )}
            </div>
            <hr/>
            <div className="list">
                {/* Filter and export */}
                <div className="d-flex">
                <div className="m-2">
                  <Button variant="primary" onClick={(e) => {
                        setInvoiceData({
                          invoiceInfo: "",
                          showModal: 'create_invoice',
                        },"CUSTOMER_DETAILS")
                    }}>
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        &nbsp;&nbsp;Create Invoice
                  </Button>
                  </div>
                  {showModal === "create_invoice" ? (
                    <div className="m-2">
                    <CreateInvoice
                      label="Create Invoice"
                      getQueryObj={getQueryObj}
                      getInvoice={getInvoice}
                      removeLines={removeLines}
                      customerId={window.location.pathname.split("/")[2]}
                      updateInvoice={updateInvoice}
                      createInvoice={createInvoice}
                      customerDetails={customerDetails}
                      setInvoiceData={setInvoiceData}
                      getInvoiceList={getInvoiceList}
                      getChartData={() => {
                        getChartData({
                          status,
                          from_date,
                          to_date,
                          customer_id: window.location.pathname.split("/")[2],
                        });
                      }}
                      getInvoiceAttachments={getInvoiceAttachments}
                    />
                    </div>
                  ) : ""}
                  {showModal === "update_invoice" ? (
                    <CreateInvoice
                    label="Update Invoice"
                    getInvoice={getInvoice}
                    removeLines={removeLines}
                    getQueryObj={getQueryObj}
                    createInvoice={createInvoice}
                    updateInvoice={updateInvoice}
                    customerDetails={customerDetails}
                    setInvoiceData={setInvoiceData}
                    getInvoiceList={getInvoiceList}
                    getChartData={() => {
                      getChartData({
                        status,
                        from_date,
                        to_date,
                        customer_id: window.location.pathname.split("/")[2],
                      });
                    }}
                    getInvoiceAttachments={getInvoiceAttachments}
                  />
                ) : ""}
                  <div className="m-2">
                  <Filter
                    getQueryObj={getQueryObj}
                    resetFilter={resetFilter}
                    getInvoice={getInvoice}
                    customerId={window.location.pathname.split("/")[2]}
                    updateInvoice={updateInvoice}
                    getChartData={getChartData}
                    createInvoice={createInvoice}
                    customerDetails={customerDetails}
                    setInvoiceData={setInvoiceData}
                    getInvoiceList={getInvoiceList}
                  />
                  </div>
                  {isrowselected ? (
                    <>
                    <div className="m-2">
                    <Button variant="primary" onClick={(e) => {
                        const ids = selectedInvoices.map(invoice => invoice.id)
                        markPaidUnPaid(ids,{
                          mark_closed: 1
                        }, () => {
                          getInvoiceList(getQueryObj());
                          getChartData({
                            status,
                            from_date,
                            to_date,
                            customer_id: window.location.pathname.split("/")[2],
                          });
                          toast.success("Invoice Closed!")
                        });
                      }}>
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        &nbsp;&nbsp;Mark Paid
                    </Button>
                    </div>
                    <div className="m-2">
                     <Button variant="primary" onClick={(e) => {
                        const ids = selectedInvoices.map(invoice => invoice.id)
                        markPaidUnPaid(ids,{
                          mark_open: 1
                        }, () => {
                          getInvoiceList(getQueryObj());
                          getChartData({
                            status,
                            from_date,
                            to_date,
                            customer_id: window.location.pathname.split("/")[2],
                          });
                          toast.success("Invoice Opened!")
                        });
                        }}>
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        &nbsp;&nbsp;Mark Unpaid
                      </Button>
                    </div>
                    </>
                  ) : ""}
                  {selectedInvoices.length ? (
                    <div className="m-2">
                    <RenderTemplate
                      customerDetails={customerDetails}
                      selectedInvoices = {selectedInvoices}
                      setInvoiceData={setInvoiceData}
                      clientDetails={dynamicformReducer.clientDetails.clientDetails}
                    />
                    </div>
                  ) : ""}
                  <div className="m-2">
                  <Export
                    getQueryObj={getExportQueryObj}
                    getInvoice={getInvoice}
                    createInvoice={createInvoice}
                    getInvoiceList={getInvoiceList}
                    exportData={exportData}
                    invoiceList={invoiceList}
                    customerDetails={customerDetails}
                    setInvoiceData={setInvoiceData}
                  />
                  </div>
                </div>
                <div className="mt-3">
                    {!isFetching ? (
                      <ReactTable
                      {...tableProps}
                      columns={_columns}
                      data={invoiceList}
                      loading={isFetching}
                      resizable={true}
                      showPagination={false}
                      noDataText={isFetching ? "" : "No data found"}
                      className="-striped -highlight"
                      freezeWhenExpanded={!isFetching}
                      subRowsKey={needExpander ? ["invoice_lines"] : []}
                      defaultSorted={[
                      {
                          id: sort_by,
                          desc: sort_order === "desc",
                      },
                      ]}
                      minRows={invoiceList.length > 20 ? 20 : invoiceList.length}
                  />
                    ) : (
                      <ShimmerTable row={5} col={5}/>
                    )}
              </div>
                <div className="row">
            <div className="filterthree">
              <div className="pull-right">
                <button
                  className="btn btn-primary m-2"
                  disabled={page === 1}
                  onClick={setPrevPage}
                  style={{width: "100px"}}
                >
                  Previous
                </button>
                <button
                  disabled={uniqBy(invoiceList || [], "id").length < 20}
                  className="btn btn-primary m-2"
                  onClick={setNextPage}
                  style={{width: "100px"}}
                >
                  Next
                </button>
              </div>
            </div>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
  customerDetails: state.customerDetails,
  dynamicformReducer: state.dynamicformReducer
});

const mapDispatchToProps = {
  getInvoice,
  exportData,
  removeLines,
  getCustomer,
  getChartData,
  resetFilter,
  createInvoice,
  updateInvoice,
  markPaidUnPaid,
  getInvoiceList,
  setInvoiceData,
  getInvoiceAttachments,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);