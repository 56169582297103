import { ReducersTypes } from "../../constants/reducerTypes.js";
import moment from "moment";

export const initialCallListFilters = {
    page: 1,
    sort_by: "name",
    sort_order: "desc",
    from_date: moment().subtract(365, "days").format("MM/DD/YYYY"),
    to_date: moment().format("DD/MM/YYYY"),
    client_customer_rel_id: "",
};

const initialState = {
    listData: [],
    isFetching: false,
    showModal: false,
    filters: {
        page: 1,
        client_customer_rel_id: "",
        status: "",
        label: "",
        from_date: moment().subtract(365, "days").format("MM/DD/YYYY"),
        to_date: moment().format("MM/DD/YYYY"),
        searchList: [],
        sort_by: "name",
        sort_order: "desc",
        showFilter: false,
        iscustomerFetching: false,
    },
    exportedData: [],
    resetCustomerFilter: false,
    showexport: false,
    exporting: false,
};

const customerList = (state = initialState, { type, payload }) => {
    switch(type) {
        case ReducersTypes.SET_CUSTOMER_LIST:
            return {
                ...state,
                ...payload.data
            };
        case ReducersTypes.SET_CUSTOMER_LIST_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload.data
                }
            }
        case ReducersTypes.RESET_CUSTOMER_LIST:
            return {
                ...initialState,
                resetCustomerFilter: !state.resetCustomerFilter
            }
        default:
            return state;
    }
};

export default customerList;