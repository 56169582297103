import { get } from "lodash";
import React, { useState } from "react";
import logo from "../assets/images/logo2.png"
import instance from "../axios";
import { store } from "../store";
import debounce from "lodash/debounce";
import ClickAwayListener from "./ClickAwayListener";
import { ReducersTypes } from "../constants/reducerTypes";
import { resetFilter } from "../containers/customerDashboard/actions";
import { resetFilter as resetInvoiceFilter } from "../containers/customerDetails/actions";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Profile from "../containers/profile/profile";
import TokenManager from "../utils/TokenManager";

function Header(props) {
    
    const [showpopup, setshowpopup] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [text, setText] = useState("");
    const name = get(props,"clientDetails.clientDetails.clientDetails.name",[])
    const isInvoicePage = window.location.pathname.split("/").includes("customer");
    const customerId = window.location.pathname.split("/")[2];

    const debounceMethod = debounce((text, searchBy) => searchCustomer(text,searchBy), 500);

    const searchCustomer = (text, searchBy) => {
        let URL = `search?level=${searchBy}&search_text=${text}`;
        if(isInvoicePage) {
            URL += `&customer_id=${customerId}`;
        }
        instance.get(URL)
            .then(res => {
                setSearchList(res.data.result);
                setIsFetching(false);
            }).catch(() => setIsFetching(false))
    }

    return (
        <>
        <Modal size="md" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header>
                <strong>User Profile</strong>
            </Modal.Header>
            <Modal.Body>
                <Profile/>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
        <ClickAwayListener onClickAway={() => {
            setSearchList([]);
        }}>
        <div className="header">
            <div>
                <img src={logo}/>
            </div>
            <div className="d-flex align-items-center">
                <i className="fa fa-search" aria-hidden="true"></i>
                <input 
                    type="text" 
                    value={text}
                    placeholder={`Search ${isInvoicePage ? "Invoice" : 'Customer'}`}
                    onChange={(e) => {
                        setIsFetching(e.target.value ? true : false);
                        if(!e.target.value && text) {
                            setText("");
                            setSearchList([]);
                            if(isInvoicePage) 
                                props.resetInvoiceFilter();
                            else
                                props.resetFilter();
                        }
                        setText(e.target.value);
                        debounceMethod(e.target.value, isInvoicePage ? "invoice" : "customer")
                    }}
                />
                {text ? 
                    <i 
                        className="fa fa-times cursor-pointer" 
                        aria-hidden="true"
                        onClick={() => {
                            setText("");
                            setSearchList([]);
                            if(isInvoicePage) 
                                props.resetInvoiceFilter();
                            else
                                props.resetFilter();
                        }}
                        />
                : ""}
            </div>
            <div className="d-inline-block pr-4 py-2">
            <span className="dropdown">
                <div
                    className="btn cursour-pointer px-0" // dropdown-toggle for down arrow icon
                    to="#"
                    role="button"
                    id="profile"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setshowpopup(!showpopup)}
                >
                    <div className="text-icon-big rounded-circle">
                    {(get(TokenManager.getUserName(),"[0]","")).toUpperCase()}
                    </div>
                </div>
                <ClickAwayListener onClickAway={() => setshowpopup(false)}>
                <div
                    className={`dropdown-menu open-profilepic-menu p-4`}
                    id="profilebox"
                    aria-labelledby="dropdownMenuLink"
                    hidden={!showpopup}
                >
                    <div className="text-icon-big rounded-circle">
                        {get(TokenManager.getUserName(),"[0]","").toUpperCase()}
                    </div>

                    <p title="" className="text-center mt-3">
                        {TokenManager.getUserName()}
                    </p>
                    <div className="row" id="dtwrapper">
                        <button 
                            type="button" 
                            class="btn btn-outline-primary"
                            onClick={() => {
                                setShowModal(true)
                            }}
                        >
                            Profile
                        </button>

                        <button 
                            type="button" 
                            class="btn btn-outline-primary mt-2"
                            onClick={() => {
                                localStorage.clear();
                                store.dispatch({ type: 'DESTROY_SESSION' });
                                window.location.reload();
                            }}
                        >
                            Logout
                        </button>
                    </div>
                </div>
                </ClickAwayListener>
            </span>
        </div>
        {/* <ClickAwayListener onClickAway={() => setIsFetching(false)}> */}
            <div className="searchList">
                <div>
                    {isFetching ? (
                        <div style={{pointerEvents: "none"}} className="loading">
                            <div class="col-3 loading">
                                <div class="snippet" data-title="dot-pulse">
                                    <div class="stage">
                                        <div class="dot-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    ) : searchList.map(list => (
                        <div onClick={() => {
                            setSearchList([]);
                            if(isInvoicePage) {
                                setText(list.invoice_number);
                                store.dispatch({
                                    type: ReducersTypes.CUSTOMER_DETAILS,
                                    payload: {
                                        isFetching: true,
                                    }
                                });
                                instance.get(`invoice?id=${list.id}`)
                                    .then(response => {
                                        store.dispatch({
                                            type: ReducersTypes.CUSTOMER_DETAILS,
                                            payload: {
                                                isFetching: false,
                                                invoiceList: [get(response, "data.result", [])],
                                            }
                                        });
                                    })
                            } else {
                                setText(list.name);
                                store.dispatch({
                                    type: ReducersTypes.SET_CUSTOMER_LIST,
                                    payload: {
                                        data: {
                                            isFetching: true
                                        }
                                    }
                                });
                                instance.get(`customer?id=${list.id}`)
                                    .then((response) => {
                                        store.dispatch({
                                            type: ReducersTypes.SET_CUSTOMER_LIST,
                                            payload: {
                                                data: {
                                                    isFetching: false,
                                                    listData: [get(response, "data.result", [])],
                                                }
                                            }
                                        })
                                    })
                                }
                        }}>
                            <i className={`fa ${isInvoicePage ? "fa-file-text" : "fa-user"}`}/>
                            <div>&nbsp;&nbsp;{isInvoicePage ? "Invoice:" : "Customer:"} {isInvoicePage ? list.invoice_number : list.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        {/* </ClickAwayListener> */}
        </div>
        </ClickAwayListener>
        </>
    )
}

const mapStateToProps = (state) => ({

});
  
const mapDispatchToProps = {
    resetFilter,
    resetInvoiceFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);