import { ReducersTypes } from "../constants/reducerTypes";

export const dynamicformReducer = (
    state = {
      forms: [],
      count: 0,
      clientDetails: ""
    },
    action
  ) => {
    switch (action.type) {
      case ReducersTypes.SET_CLIENT_DETAILS:
        return { ...state, clientDetails: action.payload }
      case ReducersTypes.DYNAMIC_FORM:
        return { ...state, forms: action.payload.forms };
      case ReducersTypes.ADD_DYNAMIC_FORM:
        return {
          ...state,
          forms: [...state.forms, ...action.payload.forms],
          count: state.count + 1,
        };
      default:
        return state;
    }
  };
  
  const globalReducers = {
    dynamicformReducer
  }

  export default globalReducers;