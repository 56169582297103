import "jspdf-autotable";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";

export const checkEmptyKeys = (obj = {}) => {
    return Object.keys(obj).filter(key => !obj[key])
}

export function downloadPDF(data,header,fileName) {
    const doc = new jsPDF();
    const cellWidth = (doc.internal.pageSize.width - 20) / (header.length || 1);
    
    try {
      doc.autoTable({
        head: [header],
        body: data,
        theme: "grid",
        styles: { fontSize: 7, halign: "center" },
        rowPageBreak: "avoid",
        margin: {horizontal: 5},
        headStyles: {
          valign: "middle",
          cellWidth,
          halign: "left",
          fillColor: "#bdc0bf",
          textColor: "black",
        },
      });
    } catch(e) {}
  
    doc.save(fileName);
    return new File([dataURItoBlob(doc.output("datauristring"))], fileName)
  }

  export const dataURItoBlob = (dataURI) => {
    var array, binary, i;
    binary = atob(dataURI.split(",")[1]);
    var mimeType = dataURI.match(/(:)([a-z\/]+)(;)/)[2];
    array = [];
    i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i++;
    }
    return new Blob([new Uint8Array(array)], {
      type: mimeType,
    });
  };

  export const convertDecimalNo = (num) => {
    if (!num) {
      return 0;
    }
    return !Number.isInteger(Number(num))
      ? Number(Number(num).toFixed(2))
      : Number(num);
  };

  export const exportHTMLPdf = (
    selector,
    fileName = "export.pdf",
    download = false,
    cb
  ) => {
    if (!selector) return;
    var opt = {
      margin:       0.4,
      filename:     fileName,
      image:        { type: 'jpeg', quality: 1 },
      html2canvas:  { scale: 2, useCORS: true },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { after: "#INV_TEMP"}
    };

    const downloadPDF = html2pdf().set(opt).from(document.querySelector("#invoiceTemplate"));
    if (download) {
      downloadPDF.save().then(() => cb())
    }

    return;
  };