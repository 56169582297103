export function generateQueryString(params) {
    if (!params || !Object.keys(params).length) return '';
    const queryString = Object.keys(params)
      .filter((d) => params[d])
      .filter(d => params[d] && d.length > 1)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    return `?${queryString}`;
  }
  
  export function queryStringToObject(queryString) {
    if (!queryString) return;
    let queryObject = {};
    let pairs = queryString.replace('?', '').split("&");
    for (let i in pairs) {
      let split = pairs[i].split("=");
      queryObject[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }
    return queryObject;
  }