import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import Customers from '../containers/customerDashboard'
import List from "../containers/customerDetails"
import CustomerDetails from "../containers/customerDetails/customerDetail";
import instance from "../axios";
import TokenManager from "../utils/TokenManager";
import { setClientData } from "../containers/actions";
import Profile from "../containers/profile/index";

function AppNavWrapper(props) {
  const routes = [
    {
      path: "/",
      Component: Customers,
      show: true,
      exact: true,
    },
    {
      path: "/customer/:cid",
      Component: CustomerDetails,
      show: true,
      exact: true,
    },
  ];

  useEffect(() => {
    const {
      setClientData
    } = props;
    const role = JSON.parse(TokenManager.getUserData())["role"];
    setClientData(role);
  },[]);

  return (
    <div className={`container-fluid bg-light-grey customerList`} id="main-body">
      <div>
        <Header {...props}/>
      </div>
      <div className="">
        <Switch>
          {routes
            .filter((d) => d.show)
            .map(({ path, Component, exact }) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                component={Component}
              />
            ))}
        </Switch>
      </div>
    </div>
  );
}

function NavWrapper(props) {
    return <AppNavWrapper {...props} />;
}

const mapStateToProps = (state) => ({
  clientDetails: state.dynamicformReducer,
});

const mapDispatchToProps = {
  setClientData
};

export default connect(mapStateToProps, mapDispatchToProps)(NavWrapper);