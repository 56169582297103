import { get } from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { CSVLink } from "react-csv";
import ClickAwayListener from "../../components/ClickAwayListener";
import { downloadPDF } from "../../utils";
import { getDate } from "../../utils/dates";
import { convertAmountWithDecimal } from "../../utils/amount";

export const ExportXLSX = (csvData, file_name='Customer List') => {
    const filename = `${file_name} ${moment().format(
      "MMMM Do YYYY-h:mm:ss-a"
    )}.xlsx`;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename);
  };
  
  function filterProperties(originalObj,visibleHeaders){
    Object.keys(visibleHeaders).forEach(key => {
      if(!visibleHeaders[key])
        delete originalObj[key];
    })
    return originalObj;
  }  

 export function ExportCSV(exportedData = []) {
  if (!exportedData.length) {
    return {
        "Invoice Number": "",
        "Total Amount Due": "",
        "Due Date": "",
        "Date Closed": "",
        "Invoice Amount": "",
        "Invoice Date": "",
    };
  }
  const csvData = [];
  exportedData.forEach((element) => {
    get(element,"invoice_lines",[]).forEach(line => {
        var taxes = get(line,"taxes","") ? get(line,"taxes","").split(",") : "";
        var cgst = taxes[0] ? Number(taxes[0].split("=")[1]) || 0.0 : 0.0;
        var igst = taxes[1] ? Number(taxes[1].split("=")[1]) || 0.0 : 0.0;
        var sgst = taxes[2] ? Number(taxes[2].split("=")[1]) || 0.0 : 0.0;

        let ele = {
            "Customer": get(element, "passenger_name") ? get(element, "passenger_name") : get(element,"customer") ? get(get(element,"customer"), "name", "") : "",
            "Invoice Number": get(element,"invoice_number","-"),
            "Date": get(element,"invoice_date","-") ? getDate(get(element,"invoice_date","-")) : "",
            "Note": get(element, 'header_note', "-"),
            "Description": get(line,"description","-"),
            "Amount": get(line, "amount", "-"),
            "SGST": (get(line, "amount")*sgst/100)+"("+sgst+"%)",
            "CGST": (get(line, "amount")*cgst/100)+"("+cgst+"%)",
            "IGST": (get(line, "amount")*igst/100)+"("+igst+"%)",
            "Final Amount": convertAmountWithDecimal(get(line, "amount") + get(line, "tax_amount"))
        };
        csvData.push(ele);
    })
  });

  return csvData;
}

const generatePDF = (exportedData = []) => {
    let headers = [];
    let info = [];
  
    const fileName = `Customer List ${moment().format(
      "MMMM Do YYYY-h:mm:ss-a"
    )}.pdf`;
  
    const newData = ExportCSV(exportedData);
   
    newData.forEach((res) => {
      info.push(Object.values(res));
      headers = Object.keys(res);
    });
  
    downloadPDF(info, headers, fileName);
};

class Export extends React.Component {

    constructor(props) {
        super(props);
    }

    exportAllData = (flag) => {
        const {
            exportData,
            getQueryObj,
            setInvoiceData,
        } = this.props;

        setInvoiceData({
            exporting: true
        }, "CUSTOMER_DETAILS");

        exportData(getQueryObj())
          .then((response) => {
            setInvoiceData({
                exportedData: get(response,"data.result",[]),
                showexport: false,
                exporting: false,
            }, "CUSTOMER_DETAILS");

                switch (flag) {
                  case "csv":
                    this.refs.csv.link.click();
                    break;
                  case "excel":
                    ExportXLSX(
                      ExportCSV(get(response,"data.result",[])),
                    );
                    break;
                  case "pdf":
                    generatePDF(get(response,"data.result",[]));
                    break;
                  default:
                    this.refs.csv.link.click();
                }
          })
          .catch((e) => {
            setInvoiceData({
                exportedData: [],
                showexport: false,
                exporting: false,
            }, "CUSTOMER_DETAILS");
            // handleAPIError(e);
          });
      };

    render() {
        const {
            customerDetails: {
                showexport,
                exporting,
                exportedData
            },
            setInvoiceData
        } = this.props;
        return (
            <div>
                <div>
                <div className="dropdown">
                    <span
                    className="btn dropdown-toggle btn-primary"
                    role="button"
                    id=""
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                      onClick={() => setInvoiceData({
                        showexport: true
                    }, "CUSTOMER_DETAILS")}
                    >
                    Export {exporting && <i className="fa fa-spinner fa-spin" />}
                    </span>
                    <ClickAwayListener onClickAway={() => showexport && setInvoiceData({
                            showexport: false
                    }, "CUSTOMER_DETAILS")}>
                      <div
                        className={`dropdown-menu dropdown-menu-right payexpdroplist cursor-pointer p-2 ${
                          showexport && "show"
                        }`}
                        aria-labelledby="dropdownMenuLink"
                        id=""
                      >
                        <CSVLink
                          data={exportedData.length ? ExportCSV(exportedData) : []}
                          ref="csv"
                          filename={`Customer List ${moment().format(
                            "MMMM Do YYYY-h:mm:ss-a"
                          )}.csv`}
                          style={{ display: "none" }}
                        />
                        <span
                          className="t-12 m-1 text-right d-block cursor-pointer"
                          onClick={() => this.exportAllData("csv")}
                        >
                          Export CSV
                        </span>
                        <span
                          className="t-12 d-block text-right m-1 cursor-pointer"
                          onClick={() => this.exportAllData("pdf")}
                        >
                          Export PDF
                        </span>
                        <span
                          className="t-12 m-1 text-right d-block cursor-pointer"
                          onClick={() => this.exportAllData("excel")}
                        >
                          Export Excel
                        </span>
                      </div>
                    </ClickAwayListener>
                </div>
                </div>
            </div>
        )
    }
}

export default Export;