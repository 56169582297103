import moment from "moment";

export const getFormattedDate = (
    date,
    ip_format = "DD/MM/YYYY",
    apiFormat = false
  ) => {
    if (date) {
      const op_format = apiFormat || "DD/MM/YYYY";
      return moment(date).format(op_format) === "Invalid date" ||
        moment(date).format(op_format) === null ||
        moment(date).format(op_format) === "null"
        ? ""
        : moment(date, ip_format).format(op_format);
    }
    return null;
  };

  export const getDate = (date) => {
    if(date)
    return moment(date).format("DD/MM/YYYY")
  }